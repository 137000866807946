/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Handle Patient specific messages
 */

	/**
	 * Add new patient by cloning an matching eligible patient into the local enterprise.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doAddFromEligible()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.addFromEligible()
	 *
	 * patientRequest: PatientRequest
	 * returns: PatientAddEligibleResponse
	 */
	import addFromEligibleRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	import addFromEligibleResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientAddEligibleResponse.json';

	export const addFromEligible = (patientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/addFromEligible';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'addFromEligible',
			requestSchema: addFromEligibleRequestSchema,
			responseSchema: addFromEligibleResponseSchema
		});
	};

	addFromEligible.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	addFromEligible.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientAddEligibleResponse.json';

	/**
	 * Associate a patient (IPID) to an enterprise (EnterpriseID).
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doAssociatePersonWithAccount()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.associatePersonWithAccount()
	 *
	 * xePatientAccount: XePatientAccount
	 * returns: XePatientAccountResponse
	 */
	import associatePersonWithAccountRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientaccount.XePatientAccount.json';
	import associatePersonWithAccountResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientaccount.XePatientAccountResponse.json';

	export const associatePersonWithAccount = (xePatientAccount, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/associatePatientToEnterprise';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xePatientAccount;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'associatePersonWithAccount',
			requestSchema: associatePersonWithAccountRequestSchema,
			responseSchema: associatePersonWithAccountResponseSchema
		});
	};

	associatePersonWithAccount.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientaccount.XePatientAccount.json';
	associatePersonWithAccount.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientaccount.XePatientAccountResponse.json';

	/**
	 * Get patient(s) by auto-completing a given string as family name, given name or patient ID.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doAutoComplete()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.autoComplete()
	 *
	 * searchText: string
	 * isUniquePersonId: boolean
	 * returns: XePatientSearch
	 */
	import autoCompleteResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch.json';

	export const autoComplete = ({searchText, isUniquePersonId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/autoCompletePatients';

		const params = {
			searchText,
			isUniquePersonId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'autoComplete',
			responseSchema: autoCompleteResponseSchema
		});
	};

	autoComplete.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch.json';

	/**
	 * Clone a existing patient record from a remote enterprise into the local enterprise.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doClone()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.clone()
	 *
	 * ipid: number
	 * isAttribution: boolean
	 * clonedComment: string
	 * enterpriseId: string
	 * clonePatientId: boolean
	 * returns: PatientCloneResponse
	 */
	import cloneResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientCloneResponse.json';

	export const clone = ({ipid, isAttribution, clonedComment, enterpriseId, clonePatientId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/clonePatient';

		const params = {
			ipid,
			isAttribution,
			clonedComment,
			enterpriseId,
			clonePatientId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'clone',
			responseSchema: cloneResponseSchema
		});
	};

	clone.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientCloneResponse.json';

	/**
	 * Compare identities of two persons (specified by their ID values) for matching strength as a measure of similarity.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doComparePerson()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.comparePerson()
	 *
	 * personId1: string
	 * personId2: string
	 * returns: PatientComparePersonResponse
	 */
	import comparePersonResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse.json';

	export const comparePerson = ({personId1, personId2, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/comparePersons';

		const params = {
			personId1,
			personId2
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'comparePerson',
			responseSchema: comparePersonResponseSchema
		});
	};

	comparePerson.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.response.PatientComparePersonResponse.json';

	/**
	 * Convert a patient from temporary status to a permanent.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doConvert()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.convert()
	 *
	 * ipid: number
	 * returns: PatientResponse
	 */
	import convertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	export const convert = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/convertPatientToPermanent';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'convert',
			responseSchema: convertResponseSchema
		});
	};

	convert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	/**
	 * Create or update a patient record. If an existing patient record is found for the same person based on identity match, the existing record is updated accordingly.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doCreateOrFind()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.createOrFind()
	 *
	 * patientRequest: PatientRequest
	 * returns: PatientResponse
	 */
	import createOrFindRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	import createOrFindResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	export const createOrFind = (patientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/createOrFindAndUpdatePatient';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'createOrFind',
			requestSchema: createOrFindRequestSchema,
			responseSchema: createOrFindResponseSchema
		});
	};

	createOrFind.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	createOrFind.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	/**
	 * Create or update a patient record, or return a list of Duplicates or Candidates for user to choose and update.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doCreateOrReturn()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.createOrReturn()
	 *
	 * patientRequest: PatientRequest
	 * returns: PatientResponse
	 */
	import createOrReturnRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	import createOrReturnResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	export const createOrReturn = (patientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/createOrReturnExisting';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'createOrReturn',
			requestSchema: createOrReturnRequestSchema,
			responseSchema: createOrReturnResponseSchema
		});
	};

	createOrReturn.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	createOrReturn.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	/**
	 * Merge a source patient to a target patient specified by a pair of ID values (of either IPID or PatientID).
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doEisMerge()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.eisMerge()
	 *
	 * patientMergePatientRequest: PatientMergePatientRequest
	 * returns: PatientMergeResponse
	 */
	import eisMergeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientMergePatientRequest.json';
	import eisMergeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientMergeResponse.json';

	export const eisMerge = (patientMergePatientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/mergePatients';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientMergePatientRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'eisMerge',
			requestSchema: eisMergeRequestSchema,
			responseSchema: eisMergeResponseSchema
		});
	};

	eisMerge.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientMergePatientRequest.json';
	eisMerge.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientMergeResponse.json';

	/**
	 * Execute all patient rules for a patient (IPID).
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doExecuteRules()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.executeRules()
	 *
	 * ipid: number
	 * returns: XePatientEmpty
	 */
	import executeRulesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientEmpty.json';

	export const executeRules = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/executePatientRules';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'executeRules',
			responseSchema: executeRulesResponseSchema
		});
	};

	executeRules.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientEmpty.json';

	/**
	 * Get patient records that are duplicate to the one with given IPID.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetDuplicates()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getDuplicates()
	 *
	 * ipid: number
	 * returns: GetListPatientIDsResponse
	 */
	import getDuplicatesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.GetListPatientIDsResponse.json';

	export const getDuplicates = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/' + ipid + '/duplicates';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getDuplicates',
			responseSchema: getDuplicatesResponseSchema
		});
	};

	getDuplicates.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.GetListPatientIDsResponse.json';

	/**
	 * Get patient by ID value. You must use either the internal ID (IPID) or external ID (PatientID)
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatient()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatient()
	 *
	 * ipid: number
	 * patientId: string
	 * responseSet: string
	 * returns: XePatientResponseSetData
	 */
	import getPatientResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetData.json';

	export const getPatient = ({ipid, patientId, responseSet, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getPatient';

		const params = {
			ipid,
			patientId,
			responseSet
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatient',
			responseSchema: getPatientResponseSchema
		});
	};

	getPatient.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetData.json';

	/**
	 * Get a patient, record history, address and address record history.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientAddressAndHistory()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientAddressAndHistory()
	 *
	 * ipid: number
	 * returns: XePatientWithHistory
	 */
	import getPatientAddressAndHistoryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory.json';

	export const getPatientAddressAndHistory = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/' + ipid + '/patientAddressAndHistory';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientAddressAndHistory',
			responseSchema: getPatientAddressAndHistoryResponseSchema
		});
	};

	getPatientAddressAndHistory.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientWithHistory.json';

	/**
	 * Get CCDA of the patient.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientCCDA()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientCCDA()
	 *
	 * ipid: number
	 * resourceId: number
	 * returns: Response
	 */
	import getPatientCCDAResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.Response.json';

	export const getPatientCCDA = ({ipid, resourceId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getCcda';

		const params = {
			ipid,
			resourceId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientCCDA',
			responseSchema: getPatientCCDAResponseSchema
		});
	};

	getPatientCCDA.responseSchema = 'com.thrasys.xnet.xmlobjects.Response.json';

	/**
	 * Get CCDA File of the patient.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientCCDAFile()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientCCDAFile()
	 *
	 * ipid: number
	 * resourceId: number
	 * compress: boolean
	 * returns: AssetXNetFile
	 */
	import getPatientCCDAFileResponseSchema from '../../schemas/com.thrasys.xnet.assets.AssetXNetFile.json';

	export const getPatientCCDAFile = ({ipid, resourceId, compress, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getCcdaFile';

		const params = {
			ipid,
			resourceId,
			compress
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientCCDAFile',
			responseSchema: getPatientCCDAFileResponseSchema
		});
	};

	getPatientCCDAFile.responseSchema = 'com.thrasys.xnet.assets.AssetXNetFile.json';

	/**
	 * Get detail cross-account patient data by IPID value of logged in account.  Cross-Account enterprises will be derived from EnterpriseScope. Logged in enterprise information will appear first.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientDetail()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientDetail()
	 *
	 * ipid: number
	 * returns: XePatientResponseSetDataCrossAccount
	 */
	import getPatientDetailResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount.json';

	export const getPatientDetail = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/detail';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientDetail',
			responseSchema: getPatientDetailResponseSchema
		});
	};

	getPatientDetail.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientResponseSetDataCrossAccount.json';

	/**
	 * Get a list of patient disclosure records.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientDisclosure()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientDisclosure()
	 *
	 * ipid: number
	 * returns: XeClinicalRecordExport
	 */
	import getPatientDisclosureResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport.json';

	export const getPatientDisclosure = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getPatientDisclosures';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientDisclosure',
			responseSchema: getPatientDisclosureResponseSchema
		});
	};

	getPatientDisclosure.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport.json';

	/**
	 * Get patient proxy users.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPatientProxies()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPatientProxies()
	 *
	 * ipid: number
	 * returns: XeUserDataPatientProxy
	 */
	import getPatientProxiesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.userdata.XeUserDataPatientProxy.json';

	export const getPatientProxies = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getPatientProxyUsers';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPatientProxies',
			responseSchema: getPatientProxiesResponseSchema
		});
	};

	getPatientProxies.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.userdata.XeUserDataPatientProxy.json';

	/**
	 * Get all active contacts (clinical, social, and patient) that are distinct by phone and IPID/StaffiD/PatientContact, of a patient or those associated to a given visit or case.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doGetPhoneContacts()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.getPhoneContacts()
	 *
	 * ipid: number
	 * ivid: number
	 * includeAllVisits: boolean
	 * includeChildVisits: boolean
	 * isDistinctPersonOnly: boolean
	 * returns: CallLogContact
	 */
	import getPhoneContactsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json';

	export const getPhoneContacts = ({ipid, ivid, includeAllVisits, includeChildVisits, isDistinctPersonOnly, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/phoneContacts';

		const params = {
			ipid,
			ivid,
			includeAllVisits,
			includeChildVisits,
			isDistinctPersonOnly
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'getPhoneContacts',
			responseSchema: getPhoneContactsResponseSchema
		});
	};

	getPhoneContacts.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json';

	/**
	 * Merge linked dueplicate patient records of a given person.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doMergeLinkedPatient()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.mergeLinkedPatient()
	 *
	 * personId: string
	 * patientPersonRequest: PatientPersonRequest
	 * returns: PatientPersonRequest
	 */
	import mergeLinkedPatientRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientPersonRequest.json';
	import mergeLinkedPatientResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientPersonRequest.json';

	export const mergeLinkedPatient = (patientPersonRequest, {personId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/mergeLinkedPatient/' + personId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientPersonRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'mergeLinkedPatient',
			requestSchema: mergeLinkedPatientRequestSchema,
			responseSchema: mergeLinkedPatientResponseSchema
		});
	};

	mergeLinkedPatient.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientPersonRequest.json';
	mergeLinkedPatient.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientPersonRequest.json';

	/**
	 * Merge a source person to a target person specified by a pair of PersonID values.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doMergePerson()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.mergePerson()
	 *
	 * patientMergePersonRequest: PatientMergePersonRequest
	 * returns: PatientMergeResponse
	 */
	import mergePersonRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientMergePersonRequest.json';
	import mergePersonResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientMergeResponse.json';

	export const mergePerson = (patientMergePersonRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/mergePersons';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientMergePersonRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'mergePerson',
			requestSchema: mergePersonRequestSchema,
			responseSchema: mergePersonResponseSchema
		});
	};

	mergePerson.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientMergePersonRequest.json';
	mergePerson.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientMergeResponse.json';

	/**
	 * Generate a HEDIS report and returns the generated fiel's ID (FileID).
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doPatientHEDISReport()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.patientHEDISReport()
	 *
	 * qualitySetAnchor: string
	 * riskManagerEnterpriseId: string
	 * riskGroupEnterpriseId: string
	 * returns: PatientHedisReportResponse
	 */
	import patientHEDISReportResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.report.PatientHedisReportResponse.json';

	export const patientHEDISReport = ({qualitySetAnchor, riskManagerEnterpriseId, riskGroupEnterpriseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/generateHedisReport';

		const params = {
			qualitySetAnchor,
			riskManagerEnterpriseId,
			riskGroupEnterpriseId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'patientHEDISReport',
			responseSchema: patientHEDISReportResponseSchema
		});
	};

	patientHEDISReport.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.report.PatientHedisReportResponse.json';

	/**
	 * Get all active contacts that are distinct in terms of provider role and person, of a patient or those associated to a given visit or case.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doQueryClinicalContacts()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.queryClinicalContacts()
	 *
	 * ipid: number
	 * ivid: number
	 * includeAllVisits: boolean
	 * includeChildVisits: boolean
	 * isDistinctPersonOnly: boolean
	 * returns: PatientClinicalContact
	 */
	import queryClinicalContactsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact.json';

	export const queryClinicalContacts = ({ipid, ivid, includeAllVisits, includeChildVisits, isDistinctPersonOnly, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getClinicalContacts';

		const params = {
			ipid,
			ivid,
			includeAllVisits,
			includeChildVisits,
			isDistinctPersonOnly
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'queryClinicalContacts',
			responseSchema: queryClinicalContactsResponseSchema
		});
	};

	queryClinicalContacts.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.staff.PatientClinicalContact.json';

	/**
	 * Get the patient's latest records of height and weight (from all accessible enterpise accounts).
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doQueryHeightWeight()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.queryHeightWeight()
	 *
	 * ipid: number
	 * returns: XeVisitObservation
	 */
	import queryHeightWeightResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitobservation.XeVisitObservation.json';

	export const queryHeightWeight = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getPatientHeightAndWeight';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'queryHeightWeight',
			responseSchema: queryHeightWeightResponseSchema
		});
	};

	queryHeightWeight.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitobservation.XeVisitObservation.json';

	/**
	 * Register a new patient, create MRD folder and a default payer.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doRegister()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.register()
	 *
	 * patientRequest: PatientRequest
	 * returns: PatientResponse
	 */
	import registerRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	import registerResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	export const register = (patientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/registerPatient';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'register',
			requestSchema: registerRequestSchema,
			responseSchema: registerResponseSchema
		});
	};

	register.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	register.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	/**
	 * Search for patients matching given criteria. The result is sorted with the closest match on top.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doSearchPatient()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.searchPatient()
	 *
	 * searchText: string
	 * searchId: string
	 * searchDob: Date
	 * searchPhone: string
	 * searchCity: string
	 * searchZipPostalCode: string
	 * sexId: string
	 * isDead: boolean
	 * withPortalAndProxyUsers: boolean
	 * isUniquePersonId: boolean
	 * isNetworkSearch: boolean
	 * active: boolean
	 * familyName: string
	 * givenName: string
	 * secondName: string
	 * ipid: number
	 * returns: XePatientSearch
	 */
	import searchPatientResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch.json';

	export const searchPatient = ({searchText, searchId, searchDob, searchPhone, searchCity, searchZipPostalCode, sexId, isDead, withPortalAndProxyUsers, isUniquePersonId, isNetworkSearch, active, familyName, givenName, secondName, ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/searchPatients';

		const params = {
			searchText,
			searchId,
			searchDob,
			searchPhone,
			searchCity,
			searchZipPostalCode,
			sexId,
			isDead,
			withPortalAndProxyUsers,
			isUniquePersonId,
			isNetworkSearch,
			active,
			familyName,
			givenName,
			secondName,
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'searchPatient',
			responseSchema: searchPatientResponseSchema
		});
	};

	searchPatient.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.XePatientSearch.json';

	/**
	 * Send the patient's care record (CCDA) to a direct mail recipient.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doSendPatientCCDA()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.sendPatientCCDA()
	 *
	 * ipid: number
	 * resourceId: number
	 * returns: PatientCcdaResponse
	 */
	import sendPatientCCDAResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse.json';

	export const sendPatientCCDA = ({ipid, resourceId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/sendCcda';

		const params = {
			ipid,
			resourceId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'sendPatientCCDA',
			responseSchema: sendPatientCCDAResponseSchema
		});
	};

	sendPatientCCDA.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientCcdaResponse.json';

	/**
	 * Get a patient's stillbirth baby(s) based on the patient's system internal.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doStillBirth()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.stillBirth()
	 *
	 * ipid: number
	 * returns: AssetXePatientBirthChild
	 */
	import stillBirthResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXePatientBirthChild.json';

	export const stillBirth = ({ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/getStillbirth';

		const params = {
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'stillBirth',
			responseSchema: stillBirthResponseSchema
		});
	};

	stillBirth.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXePatientBirthChild.json';

	/**
	 * Unmerge a previously merged source and target persons specified by their PersonID values.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doUnmergePerson()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.unmergePerson()
	 *
	 * sourcePersonId: string
	 * targetPersonId: string
	 * returns: PatientUnmergeResponse
	 */
	import unmergePersonResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientUnmergeResponse.json';

	export const unmergePerson = ({sourcePersonId, targetPersonId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/unmergePersons';

		const params = {
			sourcePersonId,
			targetPersonId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patients-svc',
			action: 'unmergePerson',
			responseSchema: unmergePersonResponseSchema
		});
	};

	unmergePerson.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientUnmergeResponse.json';

	/**
	 * Update an existing patient record.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.patient.PatientService.update()
	 *
	 * patientRequest: PatientRequest
	 * returns: PatientResponse
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';

	export const update = (patientRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patient/updatePatient';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patients-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patient.PatientResponse.json';
