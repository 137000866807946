/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Workflow and work
 */

	/**
	 * Get filtered list of work tabs
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.browse()
	 *
	 * xeWorkRequest: XeWorkRequest
	 * returns: XeWorkResponse
	 */
	import browseRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json';
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	export const browse = (xeWorkRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/tabs/list';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWorkRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-work-states-svc',
			action: 'browse',
			requestSchema: browseRequestSchema,
			responseSchema: browseResponseSchema
		});
	};

	browse.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json';
	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	/**
	 * Clear 'ownership' of the work in a particular state.
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doClearStateOwner()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.clearStateOwner()
	 *
	 * workStateOwner: WorkStateOwner
	 * returns: XeWorkResponse
	 */
	import clearStateOwnerRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';
	import clearStateOwnerResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	export const clearStateOwner = (workStateOwner, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/state/release';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = workStateOwner;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-work-states-svc',
			action: 'clearStateOwner',
			requestSchema: clearStateOwnerRequestSchema,
			responseSchema: clearStateOwnerResponseSchema
		});
	};

	clearStateOwner.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';
	clearStateOwner.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	/**
	 * Get filtered list of work
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.getDetails()
	 *
	 * xeWorkRequest: XeWorkRequest
	 * returns: XeWorkResponse
	 */
	import getDetailsRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json';
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	export const getDetails = (xeWorkRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/list';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWorkRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-work-states-svc',
			action: 'getDetails',
			requestSchema: getDetailsRequestSchema,
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkRequest.json';
	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.work.XeWorkResponse.json';

	/**
	 * Get the list of potential owners for a specified state
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doGetPotentialOwners()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.getPotentialOwners()
	 *
	 * workflowId: number
	 * workState: string
	 * processingTypeId: string
	 * tokenId: string
	 * returns: AssetXeResource
	 */
	import getPotentialOwnersResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeResource.json';

	export const getPotentialOwners = ({workflowId, workState, processingTypeId, tokenId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/state/owner/potential';

		const params = {
			workflowId,
			workState,
			processingTypeId,
			tokenId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-work-states-svc',
			action: 'getPotentialOwners',
			responseSchema: getPotentialOwnersResponseSchema
		});
	};

	getPotentialOwners.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeResource.json';

	/**
	 * Take 'ownership' of the work in a particular state.
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doSetStateOwner()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.setStateOwner()
	 *
	 * workStateOwner: WorkStateOwner
	 * returns: WorkStateOwner
	 */
	import setStateOwnerRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';
	import setStateOwnerResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';

	export const setStateOwner = (workStateOwner, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/state/owner';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = workStateOwner;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-work-states-svc',
			action: 'setStateOwner',
			requestSchema: setStateOwnerRequestSchema,
			responseSchema: setStateOwnerResponseSchema
		});
	};

	setStateOwner.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';
	setStateOwner.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOwner.json';

	/**
	 * Set the offlist status for a piece of work (y|n)
	 * @see com.thrasys.xnet.erp.work.handler.WorkStateHandler.doToggleOffList()
	 * @see com.thrasys.xnet.erp.resthandler.workstate.WorkStateService.toggleOffList()
	 *
	 * workStateOffList: WorkStateOffList
	 * returns: WorkStateOffList
	 */
	import toggleOffListRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json';
	import toggleOffListResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json';

	export const toggleOffList = (workStateOffList, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/work/state/offlist';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = workStateOffList;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-work-states-svc',
			action: 'toggleOffList',
			requestSchema: toggleOffListRequestSchema,
			responseSchema: toggleOffListResponseSchema
		});
	};

	toggleOffList.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json';
	toggleOffList.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.work.WorkStateOffList.json';
