/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Requests for clinical guideline of utilization management.
 */

	/**
	 * Get GuidelinePath and Key values for MCG CareWebQI page, e.g., url: http://xnet.thrasys.com:8080/{GuidelinePath}?authorization={Key}@{XNetAuthorization}
	 * @see com.thrasys.xnet.erp.managedcare.handler.VisitGuidelineHandler.doCreate()
	 * @see com.thrasys.xnet.erp.resthandler.visitguideline.VisitGuidelineService.create()
	 *
	 * xeVisitGuideRequest: XeVisitGuideRequest
	 * returns: XeVisitGuideRequestResponse
	 */
	import createRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest.json';
	import createResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequestResponse.json';

	export const create = (xeVisitGuideRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/guideline/create';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeVisitGuideRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-guidelines-svc',
			action: 'create',
			requestSchema: createRequestSchema,
			responseSchema: createResponseSchema
		});
	};

	create.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequest.json';
	create.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideRequestResponse.json';

	/**
	 * Record a text-based guideline for an authorization.
	 * @see com.thrasys.xnet.erp.managedcare.handler.VisitGuidelineHandler.doCreateTextGuideline()
	 * @see com.thrasys.xnet.erp.resthandler.visitguideline.VisitGuidelineService.createTextGuideline()
	 *
	 * xeVisitGuidelineText: XeVisitGuidelineText
	 * returns: XeVisitGuideline
	 */
	import createTextGuidelineRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuidelineText.json';
	import createTextGuidelineResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline.json';

	export const createTextGuideline = (xeVisitGuidelineText, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/guideline/textGuideline';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeVisitGuidelineText;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-guidelines-svc',
			action: 'createTextGuideline',
			requestSchema: createTextGuidelineRequestSchema,
			responseSchema: createTextGuidelineResponseSchema
		});
	};

	createTextGuideline.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuidelineText.json';
	createTextGuideline.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitguideline.XeVisitGuideline.json';

	/**
	 * Queries the list of potential guidelies
	 * @see com.thrasys.xnet.erp.managedcare.handler.VisitGuidelineHandler.doQueryGuidelines()
	 * @see com.thrasys.xnet.erp.resthandler.visitguideline.VisitGuidelineService.queryGuidelines()
	 *
	 * ivid: number
	 * returns: XeClinicalLinks
	 */
	import queryGuidelinesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json';

	export const queryGuidelines = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/guideline/list';

		const params = {
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-guidelines-svc',
			action: 'queryGuidelines',
			responseSchema: queryGuidelinesResponseSchema
		});
	};

	queryGuidelines.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json';
