/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Methods to add/enter/update visit providers for a patient.
 */

	/**
	 * Adding or updating a patient visit provider
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitProviderHandler.doAdd()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisitprovider.PatientVisitProviderService.add()
	 *
	 * patientVisitProviderAddRequest: PatientVisitProviderAddRequest
	 * returns: PatientVisitProviderAddRequest
	 */
	import addRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json';
	import addResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json';

	export const add = (patientVisitProviderAddRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/visitProviders/add';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientVisitProviderAddRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visit-providers-svc',
			action: 'add',
			requestSchema: addRequestSchema,
			responseSchema: addResponseSchema
		});
	};

	add.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json';
	add.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientVisitProviderAddRequest.json';

	/**
	 * Add a shift provider to a specified visits
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitProviderHandler.doAddShiftProvider()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisitprovider.PatientVisitProviderService.addShiftProvider()
	 *
	 * patientShiftProviderRequest: PatientShiftProviderRequest
	 * returns: PatientShiftProvider
	 */
	import addShiftProviderRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json';
	import addShiftProviderResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

	export const addShiftProvider = (patientShiftProviderRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/visitProviders/shiftProvider/add';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientShiftProviderRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visit-providers-svc',
			action: 'addShiftProvider',
			requestSchema: addShiftProviderRequestSchema,
			responseSchema: addShiftProviderResponseSchema
		});
	};

	addShiftProvider.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json';
	addShiftProvider.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

	/**
	 * drop a shift provider from the specified visits. Set end date to now if not specified.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitProviderHandler.doDropShiftProvider()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisitprovider.PatientVisitProviderService.dropShiftProvider()
	 *
	 * patientShiftProviderRequest: PatientShiftProviderRequest
	 * returns: PatientShiftProvider
	 */
	import dropShiftProviderRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json';
	import dropShiftProviderResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

	export const dropShiftProvider = (patientShiftProviderRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/visitProviders/shiftProvider/drop';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientShiftProviderRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visit-providers-svc',
			action: 'dropShiftProvider',
			requestSchema: dropShiftProviderRequestSchema,
			responseSchema: dropShiftProviderResponseSchema
		});
	};

	dropShiftProvider.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProviderRequest.json';
	dropShiftProvider.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

	/**
	 * Return all active/inactive patients for a specific shift provider
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitProviderHandler.doGetShiftPatients()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisitprovider.PatientVisitProviderService.getShiftPatients()
	 *
	 * timeSheetId: number
	 * active: boolean
	 * returns: PatientShiftPatient
	 */
	import getShiftPatientsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient.json';

	export const getShiftPatients = ({timeSheetId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/visitProviders/shiftPatients';

		const params = {
			timeSheetId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visit-providers-svc',
			action: 'getShiftPatients',
			responseSchema: getShiftPatientsResponseSchema
		});
	};

	getShiftPatients.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftPatient.json';

	/**
	 * Return the active shift providers for a specific visit
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitProviderHandler.doGetShiftProviders()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisitprovider.PatientVisitProviderService.getShiftProviders()
	 *
	 * ivid: number[]
	 * returns: PatientShiftProvider
	 */
	import getShiftProvidersResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';

	export const getShiftProviders = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/visitProviders/shiftProviders';

		const params = {
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visit-providers-svc',
			action: 'getShiftProviders',
			responseSchema: getShiftProvidersResponseSchema
		});
	};

	getShiftProviders.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisitprovider.PatientShiftProvider.json';
