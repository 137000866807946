/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Maintanance of staff address information.
 */

	/**
	 * Create a new XeStaffAddress record
	 * @see com.thrasys.xnet.app.user.handler.StaffAddressHandler.doInsert()
	 * @see com.thrasys.xnet.app.resthandler.staffaddress.StaffAddressService.insert()
	 *
	 * staffId: number
	 * staffAddressRequest: StaffAddressRequest
	 * returns: AssetXeStaffAddress
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staffaddress.StaffAddressRequest.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeStaffAddress.json';

	export const insert = (staffAddressRequest, {staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staffAddress/insert/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffAddressRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staff-address-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staffaddress.StaffAddressRequest.json';
	insert.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeStaffAddress.json';

	/**
	 * Update the XeStaffAddress record
	 * @see com.thrasys.xnet.app.user.handler.StaffAddressHandler.doUpdate()
	 * @see com.thrasys.xnet.app.resthandler.staffaddress.StaffAddressService.update()
	 *
	 * staffId: number
	 * addressId: string
	 * staffAddressRequest: StaffAddressRequest
	 * returns: AssetXeStaffAddress
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staffaddress.StaffAddressRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeStaffAddress.json';

	export const update = (staffAddressRequest, {staffId, addressId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staffAddress/update/' + staffId + '/address/' + addressId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffAddressRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staff-address-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staffaddress.StaffAddressRequest.json';
	update.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeStaffAddress.json';
