/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Create/update patient waitlist entries ex: a patient task.
 */

	/**
	 * insert a patient task (default waitlist categories if none) with PreferredDateTime set to noon, if midnight.
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doAddTask()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.addTask()
	 *
	 * patientTaskAddRequest: PatientTaskAddRequest
	 * returns: PatientTaskAddRequest
	 */
	import addTaskRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest.json';
	import addTaskResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest.json';

	export const addTask = (patientTaskAddRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/patientTask';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientTaskAddRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'addTask',
			requestSchema: addTaskRequestSchema,
			responseSchema: addTaskResponseSchema
		});
	};

	addTask.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest.json';
	addTask.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.PatientTaskAddRequest.json';

	/**
	 * complete a waitlist and record the ServiceBookingID when the service has been booked
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doBook()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.book()
	 *
	 * xeWaitListRequest: XeWaitListRequest
	 * returns: AssetXeWaitList
	 */
	import bookRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	import bookResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const book = (xeWaitListRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/book';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWaitListRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'book',
			requestSchema: bookRequestSchema,
			responseSchema: bookResponseSchema
		});
	};

	book.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	book.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	/**
	 * Get a list of waitlist entries matching the specified parameters. Using POST for entering specified Resources
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.browse()
	 *
	 * xeWaitListBrowseQuery: XeWaitListBrowseQuery
	 * returns: FactXeWaitListHTML
	 */
	import browseRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListBrowseQuery.json';
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML.json';

	export const browse = (xeWaitListBrowseQuery, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/browse';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWaitListBrowseQuery;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'browse',
			requestSchema: browseRequestSchema,
			responseSchema: browseResponseSchema
		});
	};

	browse.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListBrowseQuery.json';
	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.FactXeWaitListHTML.json';

	/**
	 * cancel waitlistID in payload
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doCancel()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.cancel()
	 *
	 * assetXeWaitList: AssetXeWaitList
	 * returns: AssetXeWaitList
	 */
	import cancelRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	import cancelResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const cancel = (assetXeWaitList, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/cancel';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeWaitList;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'cancel',
			requestSchema: cancelRequestSchema,
			responseSchema: cancelResponseSchema
		});
	};

	cancel.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	cancel.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	/**
	 * complete waitlistID in payload
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doComplete()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.complete()
	 *
	 * assetXeWaitList: AssetXeWaitList
	 * returns: AssetXeWaitList
	 */
	import completeRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	import completeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const complete = (assetXeWaitList, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/complete';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeWaitList;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'complete',
			requestSchema: completeRequestSchema,
			responseSchema: completeResponseSchema
		});
	};

	complete.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	complete.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	/**
	 * insert generic waitlist entry
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.insert()
	 *
	 * assetXeWaitList: AssetXeWaitList
	 * returns: AssetXeWaitList
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const insert = (assetXeWaitList, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/waitlist';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeWaitList;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	/**
	 * For the task specified, relink the IVID of the task to the IVID in the message
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doLink()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.link()
	 *
	 * xeWaitListRequest: XeWaitListRequest
	 * returns: XeWaitListRequest
	 */
	import linkRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	import linkResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';

	export const link = (xeWaitListRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/relink';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWaitListRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'link',
			requestSchema: linkRequestSchema,
			responseSchema: linkResponseSchema
		});
	};

	link.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	link.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';

	/**
	 * Mark waitlistID in payload as In Error.  An error description is required.
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.markInError()
	 *
	 * assetXeWaitList: AssetXeWaitList
	 * returns: AssetXeWaitList
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const markInError = (assetXeWaitList, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeWaitList;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	/**
	 * update waitlist data from payload
	 * @see com.thrasys.xnet.erp.scheduling.handler.WaitListHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.waitlist.WaitListService.update()
	 *
	 * xeWaitListRequest: XeWaitListRequest
	 * returns: AssetXeWaitList
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeWaitList.json';

	export const update = (xeWaitListRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/tasks';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeWaitListRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-wait-lists-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.waitlist.XeWaitListRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeWaitList.json';
