/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Access to lists of messages sent from or received by a user
 */

	/**
	 * Update an inbox folder
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doEnter()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.enter()
	 *
	 * username: string
	 * notificationFolderRequest: NotificationFolderRequest
	 * returns: NotificationFolderUpdateResponse
	 */
	import enterRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest.json';
	import enterResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderUpdateResponse.json';

	export const enter = (notificationFolderRequest, {username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/folder';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = notificationFolderRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-notification-folders-svc',
			action: 'enter',
			requestSchema: enterRequestSchema,
			responseSchema: enterResponseSchema
		});
	};

	enter.requestSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderRequest.json';
	enter.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderUpdateResponse.json';

	/**
	 * list of user's inbox folders
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQuery()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.query()
	 *
	 * username: string
	 * notificationTypeId: string
	 * folderId: number
	 * resourceId: number
	 * category: string
	 * active: boolean
	 * returns: NotificationFolderQueryResponse
	 */
	import queryResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse.json';

	export const query = ({username, notificationTypeId, folderId, resourceId, category, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/folder';

		const params = {
			notificationTypeId,
			folderId,
			resourceId,
			category,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'query',
			responseSchema: queryResponseSchema
		});
	};

	query.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryResponse.json';

	/**
	 * list of messages for the notification type and IVID (or IPID) presented as a chat
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryChatProcessed()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryChatProcessed()
	 *
	 * notificationTypeId: string
	 * isMyChat: boolean
	 * ipid: number
	 * ivid: number
	 * wellKnownId: string
	 * threadId: number
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * withAttachments: boolean
	 * returns: XeAppUserNotificationDetail
	 */
	import queryChatProcessedResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	export const queryChatProcessed = ({notificationTypeId, isMyChat, ipid, ivid, wellKnownId, threadId, sendDateMin, sendDateMax, withAttachments, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/chat/query';

		const params = {
			notificationTypeId,
			isMyChat,
			ipid,
			ivid,
			wellKnownId,
			threadId,
			sendDateMin,
			sendDateMax,
			withAttachments
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryChatProcessed',
			responseSchema: queryChatProcessedResponseSchema
		});
	};

	queryChatProcessed.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	/**
	 * unread recent (since sinceDate) inbox message count and unread all inbox message count
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryForNew()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryForNew()
	 *
	 * username: string
	 * sinceDate: Date
	 * notificationTypeId: string
	 * allUnread: string
	 * enterpriseId: string
	 * returns: NotificationFolderQueryForNewResponse
	 */
	import queryForNewResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse.json';

	export const queryForNew = ({username, sinceDate, notificationTypeId, allUnread, enterpriseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/counts';

		const params = {
			sinceDate,
			notificationTypeId,
			allUnread,
			enterpriseId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryForNew',
			responseSchema: queryForNewResponseSchema
		});
	};

	queryForNew.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderQueryForNewResponse.json';

	/**
	 * detail of  message received by the logged on user (inbox)
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryInboxDetail()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryInboxDetail()
	 *
	 * username: string
	 * itemId: number
	 * category: string
	 * returns: NotificationFolderInboxResponse
	 */
	import queryInboxDetailResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	export const queryInboxDetail = ({username, itemId, category, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/inbox/detail';

		const params = {
			itemId,
			category
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryInboxDetail',
			responseSchema: queryInboxDetailResponseSchema
		});
	};

	queryInboxDetail.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	/**
	 * list of messages received by the logged on user (inbox) without folder grouping
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryInboxProcessed()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryInboxProcessed()
	 *
	 * username: string
	 * category: string
	 * notificationTypeId: string
	 * isFlagged: boolean
	 * isUnconfirmedOnly: boolean
	 * statusId: string
	 * searchText: string
	 * withAttachments: boolean
	 * withDelegates: boolean
	 * senderResourceId: number
	 * ipid: number
	 * ivid: number
	 * threadId: number
	 * withThreadInfo: boolean
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * orderId: number
	 * notificationId: number
	 * isListDataOnly: boolean
	 * returns: NotificationFolderInboxResponse
	 */
	import queryInboxProcessedResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	export const queryInboxProcessed = ({username, category, notificationTypeId, isFlagged, isUnconfirmedOnly, statusId, searchText, withAttachments, withDelegates, senderResourceId, ipid, ivid, threadId, withThreadInfo, sendDateMin, sendDateMax, orderId, notificationId, isListDataOnly, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/inbox';

		const params = {
			category,
			notificationTypeId,
			isFlagged,
			isUnconfirmedOnly,
			statusId,
			searchText,
			withAttachments,
			withDelegates,
			senderResourceId,
			ipid,
			ivid,
			threadId,
			withThreadInfo,
			sendDateMin,
			sendDateMax,
			orderId,
			notificationId,
			isListDataOnly
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryInboxProcessed',
			responseSchema: queryInboxProcessedResponseSchema
		});
	};

	queryInboxProcessed.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	/**
	 * list of archived inbox messages of the logged on user
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryInboxTrash()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryInboxTrash()
	 *
	 * username: string
	 * category: string
	 * notificationTypeId: string
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * threadId: number
	 * returns: NotificationFolderInboxResponse
	 */
	import queryInboxTrashResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	export const queryInboxTrash = ({username, category, notificationTypeId, sendDateMin, sendDateMax, threadId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/inboxTrash';

		const params = {
			category,
			notificationTypeId,
			sendDateMin,
			sendDateMax,
			threadId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryInboxTrash',
			responseSchema: queryInboxTrashResponseSchema
		});
	};

	queryInboxTrash.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationitem.NotificationFolderInboxResponse.json';

	/**
	 * list of outbox (sent) active messages without folders
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryOutboxDetail()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryOutboxDetail()
	 *
	 * username: string
	 * notificationId: number
	 * returns: XeAppUserNotificationDetail
	 */
	import queryOutboxDetailResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	export const queryOutboxDetail = ({username, notificationId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/outbox/detail';

		const params = {
			notificationId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryOutboxDetail',
			responseSchema: queryOutboxDetailResponseSchema
		});
	};

	queryOutboxDetail.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	/**
	 * list of outbox (sent) active messages without folders
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryOutboxProcessed()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryOutboxProcessed()
	 *
	 * username: string
	 * category: string
	 * notificationTypeId: string
	 * isUnconfirmedOnly: boolean
	 * receiverResId: number
	 * withAttachments: boolean
	 * searchText: string
	 * ipid: number
	 * ivid: number
	 * threadId: number
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * isListDataOnly: boolean
	 * returns: XeAppUserNotificationDetail
	 */
	import queryOutboxProcessedResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	export const queryOutboxProcessed = ({username, category, notificationTypeId, isUnconfirmedOnly, receiverResId, withAttachments, searchText, ipid, ivid, threadId, sendDateMin, sendDateMax, isListDataOnly, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/outbox';

		const params = {
			category,
			notificationTypeId,
			isUnconfirmedOnly,
			receiverResId,
			withAttachments,
			searchText,
			ipid,
			ivid,
			threadId,
			sendDateMin,
			sendDateMax,
			isListDataOnly
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryOutboxProcessed',
			responseSchema: queryOutboxProcessedResponseSchema
		});
	};

	queryOutboxProcessed.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	/**
	 * list of archived sent messages
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryOutboxTrash()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryOutboxTrash()
	 *
	 * username: string
	 * category: string
	 * notificationTypeId: string
	 * receiverResId: number
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * threadId: number
	 * returns: XeAppUserNotificationDetail
	 */
	import queryOutboxTrashResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	export const queryOutboxTrash = ({username, category, notificationTypeId, receiverResId, sendDateMin, sendDateMax, threadId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/outboxTrash';

		const params = {
			category,
			notificationTypeId,
			receiverResId,
			sendDateMin,
			sendDateMax,
			threadId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryOutboxTrash',
			responseSchema: queryOutboxTrashResponseSchema
		});
	};

	queryOutboxTrash.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	/**
	 * list of archived inbox and outbox messages of the logged on user
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryTrash()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryTrash()
	 *
	 * username: string
	 * category: string
	 * notificationTypeId: string
	 * withAttachments: boolean
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * threadId: number
	 * returns: XeAppUserNotificationDetail
	 */
	import queryTrashResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	export const queryTrash = ({username, category, notificationTypeId, withAttachments, sendDateMin, sendDateMax, threadId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/trash';

		const params = {
			category,
			notificationTypeId,
			withAttachments,
			sendDateMin,
			sendDateMax,
			threadId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryTrash',
			responseSchema: queryTrashResponseSchema
		});
	};

	queryTrash.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotification.XeAppUserNotificationDetail.json';

	/**
	 * inbox active messages for another user
	 * @see com.thrasys.xnet.app.notification.handler.NotificationFolderHandler.doQueryUserInbox()
	 * @see com.thrasys.xnet.app.resthandler.notificationfolder.NotificationFolderService.queryUserInbox()
	 *
	 * username: string
	 * notificationTypeId: string
	 * isFlagged: boolean
	 * statusId: string
	 * searchText: string
	 * withAttachments: boolean
	 * senderResourceId: number
	 * ipid: number
	 * ivid: number
	 * sendDateMin: Date
	 * sendDateMax: Date
	 * threadId: number
	 * returns: NotificationFolderInboxRawResponse
	 */
	import queryUserInboxResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse.json';

	export const queryUserInbox = ({username, notificationTypeId, isFlagged, statusId, searchText, withAttachments, senderResourceId, ipid, ivid, sendDateMin, sendDateMax, threadId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/notification/' + username + '/proxyInbox';

		const params = {
			notificationTypeId,
			isFlagged,
			statusId,
			searchText,
			withAttachments,
			senderResourceId,
			ipid,
			ivid,
			sendDateMin,
			sendDateMax,
			threadId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-notification-folders-svc',
			action: 'queryUserInbox',
			responseSchema: queryUserInboxResponseSchema
		});
	};

	queryUserInbox.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appusernotificationfolder.NotificationFolderInboxRawResponse.json';
