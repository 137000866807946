/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Generate a report as a file
 */

	/**
	 * Get a list of XeBookInstanceReport that matches the filter criteria.
	 * @see com.thrasys.xnet.erp.reporting.handler.XeReportingHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.xereporting.XeReportingService.browse()
	 *
	 * creationUser: string
	 * reportId: number
	 * bookId: string
	 * returns: AssetXeSmartBookInstanceReport
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json';

	export const browse = ({creationUser, reportId, bookId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/report/list';

		const params = {
			creationUser,
			reportId,
			bookId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-xe-reportings-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json';

	/**
	 * Get the details for a specific report.
	 * @see com.thrasys.xnet.erp.reporting.handler.XeReportingHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.xereporting.XeReportingService.getDetails()
	 *
	 * reportId: number
	 * reportCode: string
	 * instanceId: number
	 * includeQueryData: boolean
	 * active: boolean
	 * returns: XeReportDetail
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.report.XeReportDetail.json';

	export const getDetails = ({reportId, reportCode, instanceId, includeQueryData, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/report/details';

		const params = {
			reportId,
			reportCode,
			instanceId,
			includeQueryData,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-xe-reportings-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.report.XeReportDetail.json';

	/**
	 * generate a report
	 * @see com.thrasys.xnet.erp.reporting.handler.XeReportingHandler.doPrintReport()
	 * @see com.thrasys.xnet.erp.resthandler.xereporting.XeReportingService.printReport()
	 *
	 * printReportRequest: PrintReportRequest
	 * returns: PrintReportResponse
	 */
	import printReportRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest.json';
	import printReportResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.report.PrintReportResponse.json';

	export const printReport = (printReportRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/report/output';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = printReportRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-xe-reportings-svc',
			action: 'printReport',
			requestSchema: printReportRequestSchema,
			responseSchema: printReportResponseSchema
		});
	};

	printReport.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.report.PrintReportRequest.json';
	printReport.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.report.PrintReportResponse.json';
