/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/Update a patient's personal contacts.
 */

	/**
	 * Return a list of patient contacts matching filters. Return in ContactSeqOrder.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.browse()
	 *
	 * searchTerm: string
	 * ipid: number
	 * relationTypeId: string[]
	 * isCohabitant: boolean
	 * active: boolean
	 * returns: PatientContactFullRequest
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest.json';

	export const browse = ({searchTerm, ipid, relationTypeId, isCohabitant, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact/browse';

		const params = {
			searchTerm,
			ipid,
			relationTypeId,
			isCohabitant,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-contacts-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest.json';

	/**
	 * Create a new record if no record existing with the matching values of a set of fields that are defined as a 'natural key' to identify a patient contact. If such an existing record is found,  it is updated.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doCreateOrFind()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.createOrFind()
	 *
	 * patientContactRequest: PatientContactRequest
	 * returns: PatientContactResponse
	 */
	import createOrFindRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	import createOrFindResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	export const createOrFind = (patientContactRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact/createOrFindAndUpdatePatientContact';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientContactRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-contacts-svc',
			action: 'createOrFind',
			requestSchema: createOrFindRequestSchema,
			responseSchema: createOrFindResponseSchema
		});
	};

	createOrFind.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	createOrFind.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	/**
	 * Insert a XePatientContact record
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.insert()
	 *
	 * patientContactRequest: PatientContactRequest
	 * returns: PatientContactResponse
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	export const insert = (patientContactRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientContactRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-contacts-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	/**
	 * Search patient contact by names
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doQueryPetList()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.queryPetList()
	 *
	 * ipid: number
	 * active: boolean
	 * returns: PatientContactFullRequest
	 */
	import queryPetListResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest.json';

	export const queryPetList = ({ipid, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact/petList';

		const params = {
			ipid,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-contacts-svc',
			action: 'queryPetList',
			responseSchema: queryPetListResponseSchema
		});
	};

	queryPetList.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactFullRequest.json';

	/**
	 * Search XePatientContact with the filter criteria
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doSearch()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.search()
	 *
	 * ipid: number
	 * givenName: string
	 * familyName: string
	 * relationTypeId: string
	 * active: boolean
	 * returns: PatientContactResponse
	 */
	import searchResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	export const search = ({ipid, givenName, familyName, relationTypeId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact/search';

		const params = {
			ipid,
			givenName,
			familyName,
			relationTypeId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-contacts-svc',
			action: 'search',
			responseSchema: searchResponseSchema
		});
	};

	search.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	/**
	 * Update a XePatientContact record
	 * @see com.thrasys.xnet.erp.patient.handler.PatientContactHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.patientcontact.PatientContactService.update()
	 *
	 * patientContactRequest: PatientContactRequest
	 * returns: PatientContactResponse
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';

	export const update = (patientContactRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientContact';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientContactRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-contacts-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientcontact.PatientContactResponse.json';
