/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Maintenance of organizational unit definitions such as department, team, ward, etc 
 */

	/**
	 * query orgUnits by matching parameters
	 * @see com.thrasys.xnet.erp.orgunit.handler.OrgUnitHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.orgunit.OrgUnitService.browse()
	 *
	 * orgUnitId: number
	 * orgUnitTypeId: string
	 * wellKnownId: string
	 * name: string
	 * active: boolean
	 * isAssignableToXeResource: boolean
	 * includeLocation: boolean
	 * includeOrgCatalogs: boolean
	 * includeResourceOrgUnit: boolean
	 * returns: OrgUnitDetailResponse
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse.json';

	export const browse = ({orgUnitId, orgUnitTypeId, wellKnownId, name, active, isAssignableToXeResource, includeLocation, includeOrgCatalogs, includeResourceOrgUnit, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/orgUnit/browse';

		const params = {
			orgUnitId,
			orgUnitTypeId,
			wellKnownId,
			name,
			active,
			isAssignableToXeResource,
			includeLocation,
			includeOrgCatalogs,
			includeResourceOrgUnit
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-org-units-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.app.xmlobjects.orgunit.OrgUnitDetailResponse.json';

	/**
	 * update an orgUnit
	 * @see com.thrasys.xnet.erp.orgunit.handler.OrgUnitHandler.doEnter()
	 * @see com.thrasys.xnet.erp.resthandler.orgunit.OrgUnitService.enter()
	 *
	 * xeOrgUnitDetails: XeOrgUnitDetails
	 * returns: XeOrgUnitDetails
	 */
	import enterRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';
	import enterResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';

	export const enter = (xeOrgUnitDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/orgUnit/enter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeOrgUnitDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-org-units-svc',
			action: 'enter',
			requestSchema: enterRequestSchema,
			responseSchema: enterResponseSchema
		});
	};

	enter.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';
	enter.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';

	/**
	 * query full details for an orgUnit
	 * @see com.thrasys.xnet.erp.orgunit.handler.OrgUnitHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.orgunit.OrgUnitService.getDetails()
	 *
	 * orgUnitId: number
	 * returns: XeOrgUnitDetails
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';

	export const getDetails = ({orgUnitId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/orgUnit/getDetails';

		const params = {
			orgUnitId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-org-units-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';

	/**
	 * insert an orgUnit
	 * @see com.thrasys.xnet.erp.orgunit.handler.OrgUnitHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.orgunit.OrgUnitService.insert()
	 *
	 * xeOrgUnitDetails: XeOrgUnitDetails
	 * returns: XeOrgUnitDetails
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';

	export const insert = (xeOrgUnitDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/orgUnit/insert';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeOrgUnitDetails;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-org-units-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.orgunit.XeOrgUnitDetails.json';
