// Used as an accumulation point for all schema modules loaded through the plugin
// There may be a better way through webpack but its challenging due to the recursive nature
export const isRegisteredSchemaName = (validator, schemaName) => {
  const knownSchema = validator.refs[schemaName]; //this is bad, using internal property but getSchema() has side effects
  return (knownSchema !== undefined);
};

export const registerBaseSchema = (validator, baseSchema) => {
  validator.addSchema(baseSchema);
  return validator;
};

export const toRegisterSchema = (name, baseSchema, toReferencedSchemas) => validator => {
  if (isRegisteredSchemaName(validator, name)) {
    return validator;
  }

  registerBaseSchema(validator, baseSchema);
  const childrenRequiringRegistration = toReferencedSchemas().filter(({name})=>!isRegisteredSchemaName(validator, name));

  childrenRequiringRegistration.forEach(childSchema=>childSchema.registerSchema(validator));

  return validator;
};
