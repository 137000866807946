/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage report specification (XeReportInfo).
 */

	/**
	 * Search reports available for correspondence by name and/or code
	 * @see com.thrasys.xnet.app.reporting.handler.ReportInfoHandler.doBrowse()
	 * @see com.thrasys.xnet.app.resthandler.reportinfo.ReportInfoService.browse()
	 *
	 * searchTerm: string
	 * filterValue: string
	 * templateType: string
	 * active: boolean
	 * returns: AssetXeReportInfo
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.assets.AssetXeReportInfo.json';

	export const browse = ({searchTerm, filterValue, templateType, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/reportInfo/correspondence/report';

		const params = {
			searchTerm,
			filterValue,
			templateType,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-report-infos-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.assets.AssetXeReportInfo.json';

	/**
	 * return locale information for reportid. If none or not found, will return english
	 * @see com.thrasys.xnet.app.reporting.handler.ReportInfoHandler.doGetLocale()
	 * @see com.thrasys.xnet.app.resthandler.reportinfo.ReportInfoService.getLocale()
	 *
	 * reportId: number
	 * locale: string
	 * returns: XeReportInfoResponse
	 */
	import getLocaleResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.reportinfo.XeReportInfoResponse.json';

	export const getLocale = ({reportId, locale, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/reportInfo/correspondence/report/locale';

		const params = {
			reportId,
			locale
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-report-infos-svc',
			action: 'getLocale',
			responseSchema: getLocaleResponseSchema
		});
	};

	getLocale.responseSchema = 'com.thrasys.xnet.app.xmlobjects.reportinfo.XeReportInfoResponse.json';
