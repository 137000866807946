/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Creation and maintenance of user templates and users.
 */

	/**
	 * Create a new user from message data
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doAdd()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.add()
	 *
	 * username: string
	 * addUserRequest: AddUserRequest
	 * returns: AddUserRequest
	 */
	import addRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	import addResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	export const add = (addUserRequest, {username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addUserRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'add',
			requestSchema: addRequestSchema,
			responseSchema: addResponseSchema
		});
	};

	add.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	add.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	/**
	 * Create a new user based on TemplateName and message data: Username, FamilyName, GivenName, Password). Other data in message will override template settings.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doAddFromTemplate()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.addFromTemplate()
	 *
	 * addUserRequest: AddUserRequest
	 * returns: AddUserRequest
	 */
	import addFromTemplateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	import addFromTemplateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	export const addFromTemplate = (addUserRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/template/user';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addUserRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'addFromTemplate',
			requestSchema: addFromTemplateRequestSchema,
			responseSchema: addFromTemplateResponseSchema
		});
	};

	addFromTemplate.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	addFromTemplate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	/**
	 * Create a user template
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doAddTemplate()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.addTemplate()
	 *
	 * userTemplateRequest: UserTemplateRequest
	 * returns: UserTemplateRequest
	 */
	import addTemplateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';
	import addTemplateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';

	export const addTemplate = (userTemplateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/template';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = userTemplateRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'addTemplate',
			requestSchema: addTemplateRequestSchema,
			responseSchema: addTemplateResponseSchema
		});
	};

	addTemplate.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';
	addTemplate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';

	/**
	 * Create new users based  XeUserData Array containing TemplateName, Username, FamilyName, GivenName, Password (minimum dataset) Additional data will override template values. 
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doAddTemplatedUsers()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.addTemplatedUsers()
	 *
	 * addTemplatedUsersRequest: AddTemplatedUsersRequest
	 * returns: AddUserRequest
	 */
	import addTemplatedUsersRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.addtemplatedusers.AddTemplatedUsersRequest.json';
	import addTemplatedUsersResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	export const addTemplatedUsers = (addTemplatedUsersRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/template/userlist';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addTemplatedUsersRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'addTemplatedUsers',
			requestSchema: addTemplatedUsersRequestSchema,
			responseSchema: addTemplatedUsersResponseSchema
		});
	};

	addTemplatedUsers.requestSchema = 'com.thrasys.xnet.app.xmlobjects.addtemplatedusers.AddTemplatedUsersRequest.json';
	addTemplatedUsers.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	/**
	 * Change ALL users' passwords by an 'admin' user. 
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doChangeAllPasswords()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.changeAllPasswords()
	 *
	 * userDataChangeAllPasswordsRequest: UserDataChangeAllPasswordsRequest
	 * returns: XeDocumentResponse
	 */
	import changeAllPasswordsRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserDataChangeAllPasswordsRequest.json';
	import changeAllPasswordsResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const changeAllPasswords = (userDataChangeAllPasswordsRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/access/allPasswords';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = userDataChangeAllPasswordsRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'changeAllPasswords',
			requestSchema: changeAllPasswordsRequestSchema,
			responseSchema: changeAllPasswordsResponseSchema
		});
	};

	changeAllPasswords.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataChangeAllPasswordsRequest.json';
	changeAllPasswords.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Createa DP Endpoint for a user in a domain.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doCreateDPEndpoint()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.createDPEndpoint()
	 *
	 * username: string
	 * healthDomain: string
	 * userDataCreateDPEndpointRequest: UserDataCreateDPEndpointRequest
	 * returns: UserDataCreateDBEndpointResponse
	 */
	import createDPEndpointRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserDataCreateDPEndpointRequest.json';
	import createDPEndpointResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserDataCreateDBEndpointResponse.json';

	export const createDPEndpoint = (userDataCreateDPEndpointRequest, {username, healthDomain, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/createDpEndpoint/' + username + '/dpEndpoint/' + healthDomain;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = userDataCreateDPEndpointRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'createDPEndpoint',
			requestSchema: createDPEndpointRequestSchema,
			responseSchema: createDPEndpointResponseSchema
		});
	};

	createDPEndpoint.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataCreateDPEndpointRequest.json';
	createDPEndpoint.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataCreateDBEndpointResponse.json';

	/**
	 * Associate Data element with a system generated CAPTCHA string, Call this also to return the data associated with the captcha string
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetCaptcha()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getCaptcha()
	 *
	 * length: string
	 * data: string
	 * captcha: string
	 * returns: XeUserDataCaptchaRequest
	 */
	import getCaptchaResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataCaptchaRequest.json';

	export const getCaptcha = ({length, data, captcha, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/captcha';

		const params = {
			length,
			data,
			captcha
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getCaptcha',
			responseSchema: getCaptchaResponseSchema
		});
	};

	getCaptcha.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataCaptchaRequest.json';

	/**
	 * Return history of user logons
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetLoginEnterpriseHistory()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getLoginEnterpriseHistory()
	 *
	 * username: string
	 * loginDateMin: Date
	 * loginDateMax: Date
	 * returns: XeUserLastLoginHistory
	 */
	import getLoginEnterpriseHistoryResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory.json';

	export const getLoginEnterpriseHistory = ({username, loginDateMin, loginDateMax, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/loginEnterpriseHistory';

		const params = {
			username,
			loginDateMin,
			loginDateMax
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getLoginEnterpriseHistory',
			responseSchema: getLoginEnterpriseHistoryResponseSchema
		});
	};

	getLoginEnterpriseHistory.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userlastloginhistory.XeUserLastLoginHistory.json';

	/**
	 * Login: get user menu for a particular enterprise
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetLoginMenu()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getLoginMenu()
	 *
	 * username: string
	 * menuNodeId: string
	 * returns: XeAppMenus
	 */
	import getLoginMenuResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.appmenus.XeAppMenus.json';

	export const getLoginMenu = ({username, menuNodeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/loginMenu';

		const params = {
			username,
			menuNodeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getLoginMenu',
			responseSchema: getLoginMenuResponseSchema
		});
	};

	getLoginMenu.responseSchema = 'com.thrasys.xnet.app.xmlobjects.appmenus.XeAppMenus.json';

	/**
	 * Get a system generated password string
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetPassword()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getPassword()
	 *
	 * returns: UpdateUserAccessRequest
	 */
	import getPasswordResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';

	export const getPassword = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/password';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getPassword',
			responseSchema: getPasswordResponseSchema
		});
	};

	getPassword.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';

	/**
	 * Get user terms for the given ID. If no ID value is provided, the default terms are to be returned.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetTerms()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getTerms()
	 *
	 * username: string
	 * wellKnownId: string
	 * termsId: number
	 * returns: XeQueryResponse
	 */
	import getTermsResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeQueryResponse.json';

	export const getTerms = ({username, wellKnownId, termsId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/terms/' + username;

		const params = {
			wellKnownId,
			termsId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getTerms',
			responseSchema: getTermsResponseSchema
		});
	};

	getTerms.responseSchema = 'com.thrasys.xnet.xmlobjects.XeQueryResponse.json';

	/**
	 * Get user information details for the given username, including all accessible and/or login accounts. Same data as EnterpriseLogin with no login record created.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetUser()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getUser()
	 *
	 * username: string
	 * enterpriseId: string
	 * includeFeaturesAndRights: boolean
	 * returns: FactXeUserData
	 */
	import getUserResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	export const getUser = ({username, enterpriseId, includeFeaturesAndRights, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/getUser/' + username;

		const params = {
			enterpriseId,
			includeFeaturesAndRights
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getUser',
			responseSchema: getUserResponseSchema
		});
	};

	getUser.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	/**
	 * Return a list of users that meets the  search criteria. Access data, Roles, Menus are returned for maintenance purposes.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doGetUsersMaintenance()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.getUsersMaintenance()
	 *
	 * searchText: string
	 * enterpriseId: string
	 * templateName: string
	 * email: string
	 * includeRemoteEnterprises: boolean
	 * active: boolean
	 * returns: FactXeUserData
	 */
	import getUsersMaintenanceResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	export const getUsersMaintenance = ({searchText, enterpriseId, templateName, email, includeRemoteEnterprises, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/maintenanceList';

		const params = {
			searchText,
			enterpriseId,
			templateName,
			email,
			includeRemoteEnterprises,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'getUsersMaintenance',
			responseSchema: getUsersMaintenanceResponseSchema
		});
	};

	getUsersMaintenance.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	/**
	 * Login: record a login date 'now' for the user, and return last login date, app menus, rights, all switchable accounts.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doLoginEnterprise()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.loginEnterprise()
	 *
	 * pushToken: string
	 * returns: FactXeUserData
	 */
	import loginEnterpriseResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	export const loginEnterprise = ({pushToken, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/loginEnterprise';

		const params = {
			pushToken
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'loginEnterprise',
			responseSchema: loginEnterpriseResponseSchema
		});
	};

	loginEnterprise.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.FactXeUserData.json';

	/**
	 * Update an existing user's network scope by setting access=allow / deny  for enterprises  and/or account groups.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doModifyAccountAccess()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.modifyAccountAccess()
	 *
	 * username: string
	 * userDataAccountAccess: UserDataAccountAccess
	 * returns: XeDocumentResponse
	 */
	import modifyAccountAccessRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess.json';
	import modifyAccountAccessResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const modifyAccountAccess = (userDataAccountAccess, {username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/access/enterprises/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = userDataAccountAccess;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'modifyAccountAccess',
			requestSchema: modifyAccountAccessRequestSchema,
			responseSchema: modifyAccountAccessResponseSchema
		});
	};

	modifyAccountAccess.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserDataAccountAccess.json';
	modifyAccountAccess.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Modify an existing user based on TemplateName. Existing Roles and Facilities will be cleared. Account access is additive.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doModifyFromTemplate()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.modifyFromTemplate()
	 *
	 * addUserRequest: AddUserRequest
	 * returns: AddUserRequest
	 */
	import modifyFromTemplateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	import modifyFromTemplateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	export const modifyFromTemplate = (addUserRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/template/modifyUser';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addUserRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'modifyFromTemplate',
			requestSchema: modifyFromTemplateRequestSchema,
			responseSchema: modifyFromTemplateResponseSchema
		});
	};

	modifyFromTemplate.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	modifyFromTemplate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	/**
	 * Get a list of user templates matching SearchText in TemplateName or Description.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doQueryTemplate()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.queryTemplate()
	 *
	 * searchText: string
	 * templateName: string
	 * includeTemplateDetails: boolean
	 * active: boolean
	 * returns: UserTemplateRequest
	 */
	import queryTemplateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';

	export const queryTemplate = ({searchText, templateName, includeTemplateDetails, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/template/list';

		const params = {
			searchText,
			templateName,
			includeTemplateDetails,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'queryTemplate',
			responseSchema: queryTemplateResponseSchema
		});
	};

	queryTemplate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserTemplateRequest.json';

	/**
	 * Query data from a specified URL
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doQueryURL()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.queryURL()
	 *
	 * xeUserDataUrlRequest: XeUserDataUrlRequest
	 * returns: XeUserDataUrlResponse
	 */
	import queryURLRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataUrlRequest.json';
	import queryURLResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataUrlResponse.json';

	export const queryURL = (xeUserDataUrlRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/url/query';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeUserDataUrlRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'queryURL',
			requestSchema: queryURLRequestSchema,
			responseSchema: queryURLResponseSchema
		});
	};

	queryURL.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataUrlRequest.json';
	queryURL.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.XeUserDataUrlResponse.json';

	/**
	 * Remove DP Endpoint for the user.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doRemoveDPEndpoint()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.removeDPEndpoint()
	 *
	 * username: string
	 * returns: XeDocumentResponse
	 */
	import removeDPEndpointResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const removeDPEndpoint = ({username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/removeDpEndpoint/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'removeDPEndpoint',
			responseSchema: removeDPEndpointResponseSchema
		});
	};

	removeDPEndpoint.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Return a list of users that meets the  search criteria. This is used to produce a list of staff that can login v.s. staff that are not users.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doSearchResources()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.searchResources()
	 *
	 * searchTerm: string
	 * resourceTypeId: string
	 * enterpriseId: string
	 * resourceIsValid: boolean
	 * resourceActive: boolean
	 * userActive: boolean
	 * includeInDirectory: boolean
	 * isDirectorySearch: boolean
	 * returns: UserResourceSearch
	 */
	import searchResourcesResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserResourceSearch.json';

	export const searchResources = ({searchTerm, resourceTypeId, enterpriseId, resourceIsValid, resourceActive, userActive, includeInDirectory, isDirectorySearch, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/list';

		const params = {
			searchTerm,
			resourceTypeId,
			enterpriseId,
			resourceIsValid,
			resourceActive,
			userActive,
			includeInDirectory,
			isDirectorySearch
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'searchResources',
			responseSchema: searchResourcesResponseSchema
		});
	};

	searchResources.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserResourceSearch.json';

	/**
	 * ** Deprecated -- Use SearchResources() with IncludeInDirectory=true, isDirectorySearch=true instead. Search all user resource records that can be sent notifications e.g. Is valid, active, and in directory. Filter the results so only 1 record per user is returned. The selection criteria is 1. match logged in enterprise, 2. if does not exist then to default logon enterprise.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doSearchSendToResources()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.searchSendToResources()
	 *
	 * searchTerm: string
	 * resourceTypeId: string
	 * enterpriseId: string
	 * resourceActive: boolean
	 * returns: UserResourceSearch
	 */
	import searchSendToResourcesResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UserResourceSearch.json';

	export const searchSendToResources = ({searchTerm, resourceTypeId, enterpriseId, resourceActive, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/sendToResources';

		const params = {
			searchTerm,
			resourceTypeId,
			enterpriseId,
			resourceActive
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'searchSendToResources',
			responseSchema: searchSendToResourcesResponseSchema
		});
	};

	searchSendToResources.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UserResourceSearch.json';

	/**
	 * Set a user's default login enterprise account
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doSetDefaultEnterprise()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.setDefaultEnterprise()
	 *
	 * username: string
	 * enterpriseId: string
	 * returns: GetUserRequest
	 */
	import setDefaultEnterpriseResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.GetUserRequest.json';

	export const setDefaultEnterprise = ({username, enterpriseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/defaultEnterprise/' + username + '/default/' + enterpriseId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-user-datas-svc',
			action: 'setDefaultEnterprise',
			responseSchema: setDefaultEnterpriseResponseSchema
		});
	};

	setDefaultEnterprise.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.GetUserRequest.json';

	/**
	 * Set a user's preferences for a specific menu node.  The MenuNodeID is required and the Delta data - with changes - should be returned in full.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doSetMenuPreference()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.setMenuPreference()
	 *
	 * username: string
	 * assetXeAppMenuPreference: AssetXeAppMenuPreference
	 * returns: AssetXeAppMenuPreference
	 */
	import setMenuPreferenceRequestSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json';
	import setMenuPreferenceResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json';

	export const setMenuPreference = (assetXeAppMenuPreference, {username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userData/menuPreference/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeAppMenuPreference;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'setMenuPreference',
			requestSchema: setMenuPreferenceRequestSchema,
			responseSchema: setMenuPreferenceResponseSchema
		});
	};

	setMenuPreference.requestSchema = 'com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json';
	setMenuPreference.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppMenuPreference.json';

	/**
	 * Update an existing user data including XeResource. Will not update password, lock, or Staff Address.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doUpdate()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.update()
	 *
	 * username: string
	 * addUserRequest: AddUserRequest
	 * returns: AddUserRequest
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	export const update = (addUserRequest, {username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/userdata/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addUserRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';
	update.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.AddUserRequest.json';

	/**
	 * Update an existing users access. Supported actions: PasswordChange, PasswordExpire, Lock, PushToken, Directory. On PasswordChange, notify the user of the change by specified method at specified address.
	 * @see com.thrasys.xnet.app.user.handler.UserDataHandler.doUpdateAccess()
	 * @see com.thrasys.xnet.app.resthandler.userdata.UserDataService.updateAccess()
	 *
	 * updateUserAccessRequest: UpdateUserAccessRequest
	 * returns: UpdateUserAccessRequest
	 */
	import updateAccessRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';
	import updateAccessResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';

	export const updateAccess = (updateUserAccessRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/users/access';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = updateUserAccessRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-user-datas-svc',
			action: 'updateAccess',
			requestSchema: updateAccessRequestSchema,
			responseSchema: updateAccessResponseSchema
		});
	};

	updateAccess.requestSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';
	updateAccess.responseSchema = 'com.thrasys.xnet.app.xmlobjects.userdata.UpdateUserAccessRequest.json';
