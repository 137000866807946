/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/maintain uploaded documents and document sets.
 */

	/**
	 * Add a new  set item.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doAddSetItem()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.addSetItem()
	 *
	 * assetXeScanDocSet: AssetXeScanDocSet
	 * returns: AssetXeScanDocSet
	 */
	import addSetItemRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';
	import addSetItemResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';

	export const addSetItem = (assetXeScanDocSet, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeScanDocSet;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'addSetItem',
			requestSchema: addSetItemRequestSchema,
			responseSchema: addSetItemResponseSchema
		});
	};

	addSetItem.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';
	addSetItem.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';

	/**
	 * Search for uploaded files.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.browse()
	 *
	 * itemId: number
	 * scanDocId: number
	 * statusCode: string
	 * categoryId: string
	 * docTypeId: string
	 * description: string
	 * documentTypeId: string
	 * fileType: string
	 * ipid: number
	 * uploadDateMin: Date
	 * uploadDateMax: Date
	 * active: boolean
	 * returns: XeScanDocSetDetails
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	export const browse = ({itemId, scanDocId, statusCode, categoryId, docTypeId, description, documentTypeId, fileType, ipid, uploadDateMin, uploadDateMax, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/list';

		const params = {
			itemId,
			scanDocId,
			statusCode,
			categoryId,
			docTypeId,
			description,
			documentTypeId,
			fileType,
			ipid,
			uploadDateMin,
			uploadDateMax,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-doc-sets-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	/**
	 * Clear 'ownership' of the attachment.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doClearOwner()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.clearOwner()
	 *
	 * xeScanDocSetOwner: XeScanDocSetOwner
	 * returns: XeScanDocSetOwner
	 */
	import clearOwnerRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';
	import clearOwnerResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';

	export const clearOwner = (xeScanDocSetOwner, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/owner/release';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDocSetOwner;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'clearOwner',
			requestSchema: clearOwnerRequestSchema,
			responseSchema: clearOwnerResponseSchema
		});
	};

	clearOwner.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';
	clearOwner.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';

	/**
	 * Get details for the given ID.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.getDetails()
	 *
	 * itemId: number
	 * returns: XeScanDocSetDetails
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	export const getDetails = ({itemId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/details/' + itemId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-doc-sets-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	/**
	 * Get details for the given ID.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doGetFromSpec()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.getFromSpec()
	 *
	 * itemId: number
	 * ipid: number
	 * className: string
	 * returns: XeScanDocSetConvertResponse
	 */
	import getFromSpecResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse.json';

	export const getFromSpec = ({itemId, ipid, className, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/instance/' + itemId;

		const params = {
			ipid,
			className
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-doc-sets-svc',
			action: 'getFromSpec',
			responseSchema: getFromSpecResponseSchema
		});
	};

	getFromSpec.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetConvertResponse.json';

	/**
	 * Get the list of potential owners for the attachment
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doGetPotentialOwners()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.getPotentialOwners()
	 *
	 * documentTypeId: string
	 * returns: AssetXeResource
	 */
	import getPotentialOwnersResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeResource.json';

	export const getPotentialOwners = ({documentTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/owner/potential';

		const params = {
			documentTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-doc-sets-svc',
			action: 'getPotentialOwners',
			responseSchema: getPotentialOwnersResponseSchema
		});
	};

	getPotentialOwners.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeResource.json';

	/**
	 * reorganize the existing file into one or more files rotating pages if desired. Optionally, update originating set and scandocID
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doOrganizeItemPDF()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.organizeItemPDF()
	 *
	 * itemId: number
	 * xeScanDocSetOrganize: XeScanDocSetOrganize
	 * returns: XeScanDocSetDetails
	 */
	import organizeItemPDFRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOrganize.json';
	import organizeItemPDFResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	export const organizeItemPDF = (xeScanDocSetOrganize, {itemId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/organize/' + itemId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDocSetOrganize;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'organizeItemPDF',
			requestSchema: organizeItemPDFRequestSchema,
			responseSchema: organizeItemPDFResponseSchema
		});
	};

	organizeItemPDF.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOrganize.json';
	organizeItemPDF.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	/**
	 * Take 'ownership' of the attachment.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doSetOwner()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.setOwner()
	 *
	 * xeScanDocSetOwner: XeScanDocSetOwner
	 * returns: XeScanDocSetOwner
	 */
	import setOwnerRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';
	import setOwnerResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';

	export const setOwner = (xeScanDocSetOwner, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/owner';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDocSetOwner;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'setOwner',
			requestSchema: setOwnerRequestSchema,
			responseSchema: setOwnerResponseSchema
		});
	};

	setOwner.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';
	setOwner.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetOwner.json';

	/**
	 * split the existing file into several files Optionally, update originating set and scandocID
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doSplitItemPDF()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.splitItemPDF()
	 *
	 * itemId: number
	 * xeScanDocSetSplit: XeScanDocSetSplit
	 * returns: XeScanDocSetDetails
	 */
	import splitItemPDFRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit.json';
	import splitItemPDFResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	export const splitItemPDF = (xeScanDocSetSplit, {itemId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/split/' + itemId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDocSetSplit;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'splitItemPDF',
			requestSchema: splitItemPDFRequestSchema,
			responseSchema: splitItemPDFResponseSchema
		});
	};

	splitItemPDF.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetSplit.json';
	splitItemPDF.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocSetDetails.json';

	/**
	 * update a  set item.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocSetHandler.doUpdateSetItem()
	 * @see com.thrasys.xnet.erp.resthandler.scandocset.ScanDocSetService.updateSetItem()
	 *
	 * itemId: number
	 * xeScanDocUpdate: XeScanDocUpdate
	 * returns: AssetXeScanDocSet
	 */
	import updateSetItemRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json';
	import updateSetItemResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';

	export const updateSetItem = (xeScanDocUpdate, {itemId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/item/' + itemId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDocUpdate;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-doc-sets-svc',
			action: 'updateSetItem',
			requestSchema: updateSetItemRequestSchema,
			responseSchema: updateSetItemResponseSchema
		});
	};

	updateSetItem.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandocset.XeScanDocUpdate.json';
	updateSetItem.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeScanDocSet.json';
