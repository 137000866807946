
import { buildInfo as servicesBuildInfo } from 'services';


import buildInfo from './generated/buildInfo';

const toDisplayHash = (overrideValue, { version, buildHash }) => {
  const adjustedVersion = version !== '0.0.0.0' ? `v${version} - ` : '';

  return overrideValue || `${adjustedVersion}${buildHash}`;
};

// Information coming from webpack defined dependencies
export const info = {
  VERSION: buildInfo.version,
  BUILD_DATE: buildInfo.buildDate,
  APP_HASH: buildInfo.buildHash,
  SERVICES_HASH: toDisplayHash(buildInfo.servicesHash, servicesBuildInfo),
};

export default info;
