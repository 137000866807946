import { createElement } from 'react';
import { render } from 'react-dom';
import { XeAppContainer } from 'xnetjs/index.js';
import { toDefaultAppUniverse } from './appUniverse.js';
import { /*registerRumSideEffects,*/ buildRUMLabels } from './urlUtils.js';
import serverConfig from './serverConfig.js';

const labels = buildRUMLabels();
/*eslint-disable no-undef*/
// if (process.env.NODE_ENV !== 'development') {
//   // initializing apm-rum
//   registerRumSideEffects(serverConfig, labels);
// }

const appDomRoot = document.querySelector('#appTarget');
const appRoot = appDomRoot.querySelector('#app');
const toastRoot = appDomRoot.querySelector('#toast');
const loadingMaskRoot = appDomRoot.querySelector('#loading-mask');
const defaultUniverse = toDefaultAppUniverse(
  {
    toAppDomRoot: () => appDomRoot,
    toAppRoot: () => appRoot,
    toToastRoot: () => toastRoot,
    toLoadingMaskRoot: () => loadingMaskRoot,
  },
  serverConfig,
  labels
);

render(
  createElement(XeAppContainer, {
    universe: defaultUniverse,
    key: 'RootView',
  }),
  appRoot
);
