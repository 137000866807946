/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Custom XeAppProperty handler
 */

	/**
	 * enter a property
	 * @see com.thrasys.xnet.app.common.handler.PropertyHandler.doEnter()
	 * @see com.thrasys.xnet.app.resthandler.property.PropertyService.enter()
	 *
	 * assetXeAppProperty: AssetXeAppProperty
	 * returns: AssetXeAppProperty
	 */
	import enterRequestSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppProperty.json';
	import enterResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppProperty.json';

	export const enter = (assetXeAppProperty, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/property/enter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeAppProperty;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-propertys-svc',
			action: 'enter',
			requestSchema: enterRequestSchema,
			responseSchema: enterResponseSchema
		});
	};

	enter.requestSchema = 'com.thrasys.xnet.app.assets.AssetXeAppProperty.json';
	enter.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppProperty.json';

	/**
	 * query for property
	 * @see com.thrasys.xnet.app.common.handler.PropertyHandler.doQuery()
	 * @see com.thrasys.xnet.app.resthandler.property.PropertyService.query()
	 *
	 * propName: string
	 * returns: AssetXeAppProperty
	 */
	import queryResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppProperty.json';

	export const query = ({propName, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/property/query';

		const params = {
			propName
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-propertys-svc',
			action: 'query',
			responseSchema: queryResponseSchema
		});
	};

	query.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppProperty.json';

	/**
	 * query for property
	 * @see com.thrasys.xnet.app.common.handler.PropertyHandler.doQueryDirect()
	 * @see com.thrasys.xnet.app.resthandler.property.PropertyService.queryDirect()
	 *
	 * propName: string
	 * propValue: string
	 * active: boolean
	 * envSpecific: boolean
	 * returns: AssetXeAppProperty
	 */
	import queryDirectResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppProperty.json';

	export const queryDirect = ({propName, propValue, active, envSpecific, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/property/QueryDirect';

		const params = {
			propName,
			propValue,
			active,
			envSpecific
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-propertys-svc',
			action: 'queryDirect',
			responseSchema: queryDirectResponseSchema
		});
	};

	queryDirect.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppProperty.json';
