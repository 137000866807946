import { Subject } from 'rxjs';
//import { LicenseManager } from 'ag-grid-enterprise';
import { featureModules } from 'features';
import { appReady$, appInteractive$, userActivity$ } from './appEnvironment';
import authFeature from './authFeature.js';
import info from './info.js';

import { featureModules as xnetFeatureModules } from 'xnetjs';
import { toEnvironment } from 'xnetjs/environment/deploy';

import { exportComponentReducer } from 'xnetjs/fp/featureDefReducers';

/*eslint-disable no-undef,no-console*/
export const toDefaultAppUniverse = (
  toDomRoots,
  serverConfig = {},
  labels = {}
) => {
  /*All injected dependencies*/
  try {
    const featureCollection = Object.entries({
      ...featureModules,
      ...xnetFeatureModules,
    }).reduce(exportComponentReducer, {});

    const environment = toEnvironment(window, 'htmlIFrame');

    const serviceErrors$ = new Subject();
    const notifications$ = new Subject();

    const toServiceErrors$ = () => {
      //lazy resolution
      return serviceErrors$;
    };

    const toNotifications$ = () => {
      //lazy resolution
      return notifications$;
    };

    return {
      APP_NAME: APP_NAME,
      info,
      serverConfig,
      environment,
      featureCollection,
      toDomRoots,
      staticFeatures: {
        authFeature,
      },
      environmentEpics: {
        appInteractive$,
        appReady$,
        userActivity$,
      },
      streams: {
        toServiceErrors$,
        toNotifications$,
      },
      labels,
    };
  } catch (e) {
    if (e instanceof ReferenceError) {
      window.alert('Fatal error building appUniverse: ' + e);
    }
    console.error(e);
  } finally {
    const formatInfo = (info = {}) =>
      Object.keys(info)
        .map((key) => `${key}: ${info[key]}`)
        .join('\n');
    console.log(formatInfo(info));
  }
};
/*eslint-enable no-undef,no-console*/
