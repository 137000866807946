/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Maintenance and access to reference data like types and status (backed by asset XeAppRefData).
 */

	/**
	 * Search reference data records by name string.
	 * @see com.thrasys.xnet.app.common.handler.ReferenceDataHandler.doBrowse()
	 * @see com.thrasys.xnet.app.resthandler.referencedata.ReferenceDataService.browse()
	 *
	 * searchTerm: string
	 * active: boolean
	 * returns: AssetXeAppRefData
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppRefData.json';

	export const browse = ({searchTerm, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/refData/browse';

		const params = {
			searchTerm,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-reference-datas-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppRefData.json';

	/**
	 * Get data for specified ReferenceDataIDs
	 * @see com.thrasys.xnet.app.common.handler.ReferenceDataHandler.doQueryConvert()
	 * @see com.thrasys.xnet.app.resthandler.referencedata.ReferenceDataService.queryConvert()
	 *
	 * referenceDataId: string[]
	 * active: boolean
	 * referenceDataCategoryId: string
	 * returns: XeAppReferenceData
	 */
	import queryConvertResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.refdata.XeAppReferenceData.json';

	export const queryConvert = ({referenceDataId, active, referenceDataCategoryId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/refData';

		const params = {
			referenceDataId,
			active,
			referenceDataCategoryId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-reference-datas-svc',
			action: 'queryConvert',
			responseSchema: queryConvertResponseSchema
		});
	};

	queryConvert.responseSchema = 'com.thrasys.xnet.app.xmlobjects.refdata.XeAppReferenceData.json';
