/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Methods pertaining to smartbook data
 */

	/**
	 * Return the full details of a specific abstract render tree.
	 * @see com.thrasys.xnet.erp.clinical.handler.SmartBookHandler.doQueryBookAsInstance()
	 * @see com.thrasys.xnet.erp.resthandler.smartbook.SmartBookService.queryBookAsInstance()
	 *
	 * bookId: string
	 * ipid: number
	 * ivid: number
	 * returns: XeSmartBookInstance
	 */
	import queryBookAsInstanceResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

	export const queryBookAsInstance = ({bookId, ipid, ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/smartbooks/instanceDetails/' + bookId;

		const params = {
			ipid,
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-smart-books-svc',
			action: 'queryBookAsInstance',
			responseSchema: queryBookAsInstanceResponseSchema
		});
	};

	queryBookAsInstance.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

	/**
	 * Return the list of related nodes for the specified id.
	 * @see com.thrasys.xnet.erp.clinical.handler.SmartBookHandler.doQueryBookRelation()
	 * @see com.thrasys.xnet.erp.resthandler.smartbook.SmartBookService.queryBookRelation()
	 *
	 * bookId: string
	 * returns: XeSmartBookCategory
	 */
	import queryBookRelationResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.smartbookcategory.XeSmartBookCategory.json';

	export const queryBookRelation = ({bookId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/smartbooks/relatedList/' + bookId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-smart-books-svc',
			action: 'queryBookRelation',
			responseSchema: queryBookRelationResponseSchema
		});
	};

	queryBookRelation.responseSchema = 'com.thrasys.xnet.app.xmlobjects.smartbookcategory.XeSmartBookCategory.json';
