import { fromEvent, merge } from 'rxjs';
import {
  delay,
  map,
  mapTo,
  filter,
  distinctUntilChanged,
  startWith,
  throttleTime,
} from 'rxjs/operators';
import { isBoolean } from 'xnetjs/fp/pred';

import { APP_READY } from 'xnetjs/enterpriseContainer/XeAppContainer/actions';

export const appReady$ = (action$, state$, dependencies) => {
  const {
    environment: { toWindow },
  } = dependencies;

  const { document } = toWindow();

  return fromEvent(document, 'DOMContentLoaded').pipe(
    mapTo({
      type: APP_READY,
      value: true,
    })
  );
};

//Some browsers implement other events such as unloading and prerender, we will consider those types of
//inactive for now and specifically exclude any events we are unaware of to not introduce weird edge conditions
const visibilityMap = {
  visible: true,
  hidden: false,
  prerender: false,
  unloading: false,
};

export const appInteractive$ = (action$, state$, dependencies) => {
  const {
    environment: { toWindow },
  } = dependencies;

  const { document } = toWindow();

  const interactive = visibilityMap[document.visibilityState] || false;

  return fromEvent(document, 'visibilitychange').pipe(
    delay(1), //can't check the property synchronously or will yield previous result
    map(() => visibilityMap[document.visibilityState]),
    filter(isBoolean),
    startWith(interactive),
    distinctUntilChanged(),
    map((interactive) => {
      return {
        type: 'APP_INTERACTIVE',
        value: interactive,
      };
    })
  );
};

export const userActivity$ = (action$, state$, dependencies) => {
  const {
    environment: { toWindow },
  } = dependencies;

  const { document } = toWindow();

  const activityEvents = ['mousemove', 'keyup', 'touchstart', 'scroll'];
  const userActivityEvent$s = activityEvents.map((name) =>
    //No need to keep the events in memory, toss them
    fromEvent(document, name).pipe(mapTo(true))
  );

  //Every 5 seconds should be just fine
  return merge(...userActivityEvent$s).pipe(
    throttleTime(5000),
    map((interactive) => {
      return {
        type: 'USER_LAST_ACTIVE',
        value: new Date(),
      };
    })
  );
};

export default appReady$;
