/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Creation and maintenance of staff.
 */

	/**
	 * Activate the Staff
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doActivate()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.activate()
	 *
	 * staffId: number
	 * returns: StaffSimpleRequest
	 */
	import activateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';

	export const activate = ({staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/activate/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'activate',
			responseSchema: activateResponseSchema
		});
	};

	activate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';

	/**
	 * Check the Id Useage. Either ExternalID or NationalProviderID should be provided
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doCheckIdUseage()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.checkIdUseage()
	 *
	 * staffId: number
	 * externalId: string
	 * nationalProviderId: string
	 * isValid: boolean
	 * returns: StaffSimpleResponse
	 */
	import checkIdUseageResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	export const checkIdUseage = ({staffId, externalId, nationalProviderId, isValid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/checkIdUseage';

		const params = {
			staffId,
			externalId,
			nationalProviderId,
			isValid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'checkIdUseage',
			responseSchema: checkIdUseageResponseSchema
		});
	};

	checkIdUseage.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	/**
	 * CreateOrFind - This method will make sure the the staff and resource assets exists forthe staff. It will also update the EIS staff master.
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doCreateOrFind()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.createOrFind()
	 *
	 * staffRequest: StaffRequest
	 * returns: StaffResponse
	 */
	import createOrFindRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffRequest.json';
	import createOrFindResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffResponse.json';

	export const createOrFind = (staffRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/createOrFind';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staffs-svc',
			action: 'createOrFind',
			requestSchema: createOrFindRequestSchema,
			responseSchema: createOrFindResponseSchema
		});
	};

	createOrFind.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffRequest.json';
	createOrFind.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffResponse.json';

	/**
	 * Deactivate the Staff
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doDeactivate()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.deactivate()
	 *
	 * staffId: number
	 * returns: StaffSimpleRequest
	 */
	import deactivateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';

	export const deactivate = ({staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/deactivate/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'deactivate',
			responseSchema: deactivateResponseSchema
		});
	};

	deactivate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';

	/**
	 * Get provider by Ids
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doGetProviderAndAddressByIds()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.getProviderAndAddressByIds()
	 *
	 * staffId: number
	 * staffAddressId: string
	 * staffId_EnterpriseId: string
	 * returns: StaffSearchByTypeRequest
	 */
	import getProviderAndAddressByIdsResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	export const getProviderAndAddressByIds = ({staffId, staffAddressId, staffId_EnterpriseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/getProviderAndAddressByIds';

		const params = {
			staffId,
			staffAddressId,
			staffId_EnterpriseId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'getProviderAndAddressByIds',
			responseSchema: getProviderAndAddressByIdsResponseSchema
		});
	};

	getProviderAndAddressByIds.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	/**
	 * Get the Signature
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doGetSignature()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.getSignature()
	 *
	 * staffId: number
	 * returns: StaffSimpleResponse
	 */
	import getSignatureResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	export const getSignature = ({staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/getSignature/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'getSignature',
			responseSchema: getSignatureResponseSchema
		});
	};

	getSignature.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	/**
	 * get Staff details for given StaffID
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doGetStaff()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.getStaff()
	 *
	 * staffId: number
	 * returns: BaseXeStaff
	 */
	import getStaffResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff.json';

	export const getStaff = ({staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/getStaff/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'getStaff',
			responseSchema: getStaffResponseSchema
		});
	};

	getStaff.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff.json';

	/**
	 * get Staff details for given Username
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doGetStaffId()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.getStaffId()
	 *
	 * username: string
	 * returns: BaseXeResource
	 */
	import getStaffIdResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.resource.BaseXeResource.json';

	export const getStaffId = ({username, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/getStaffId/' + username;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'getStaffId',
			responseSchema: getStaffIdResponseSchema
		});
	};

	getStaffId.responseSchema = 'com.thrasys.xnet.app.xmlobjects.resource.BaseXeResource.json';

	/**
	 * XeStaff Lookup - Look for Name and near matches. With IncludeAll=true, will  return duplicate StaffMasterID
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doLookup()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.lookup()
	 *
	 * staffQueryRequest: StaffQueryRequest
	 * returns: StaffMergeCandidateResponse
	 */
	import lookupRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest.json';
	import lookupResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffMergeCandidateResponse.json';

	export const lookup = (staffQueryRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/lookup';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffQueryRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staffs-svc',
			action: 'lookup',
			requestSchema: lookupRequestSchema,
			responseSchema: lookupResponseSchema
		});
	};

	lookup.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryRequest.json';
	lookup.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffMergeCandidateResponse.json';

	/**
	 * Merge Temp Providers to Non Temp Provider, Input = StaffMasterID, EnterpriseID, ExcludeUsers
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doMergeTemps()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.mergeTemps()
	 *
	 * enterpriseId: string
	 * staffMasterId: string
	 * excludeUsers: string
	 * returns: XeDocumentResponse
	 */
	import mergeTempsResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const mergeTemps = ({enterpriseId, staffMasterId, excludeUsers, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/mergeTemps';

		const params = {
			enterpriseId,
			staffMasterId,
			excludeUsers
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'mergeTemps',
			responseSchema: mergeTempsResponseSchema
		});
	};

	mergeTemps.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Search as User
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doQueryFacilityStaff()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.queryFacilityStaff()
	 *
	 * staffId: number
	 * enterpriseId: string
	 * username: string
	 * nationalProviderId: string
	 * givenName: string
	 * familyName: string
	 * returns: StaffSimpleResponse
	 */
	import queryFacilityStaffResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	export const queryFacilityStaff = ({staffId, enterpriseId, username, nationalProviderId, givenName, familyName, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/queryFacilityStaff/' + staffId;

		const params = {
			enterpriseId,
			username,
			nationalProviderId,
			givenName,
			familyName
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'queryFacilityStaff',
			responseSchema: queryFacilityStaffResponseSchema
		});
	};

	queryFacilityStaff.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	/**
	 * Save the Signature. SignatureFileID, FileData(required), FileName(required) are the parameters
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSaveSignature()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.saveSignature()
	 *
	 * staffId: number
	 * fileData: string
	 * fileName: string
	 * signatureFileId: number
	 * returns: StaffSimpleResponse
	 */
	import saveSignatureResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	export const saveSignature = ({staffId, fileData, fileName, signatureFileId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/saveSignature/' + staffId;

		const params = {
			fileData,
			fileName,
			signatureFileId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'saveSignature',
			responseSchema: saveSignatureResponseSchema
		});
	};

	saveSignature.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleResponse.json';

	/**
	 * Search for Staff who are users - optionally with a speciic right
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchAsRightsUser()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchAsRightsUser()
	 *
	 * searchText: string
	 * resourceTypeId: string
	 * rightId: string
	 * resourceTypeRecurse: boolean
	 * isValid: boolean
	 * includeSignatureFile: boolean
	 * active: boolean
	 * returns: StaffAsRightsUserResponse
	 */
	import searchAsRightsUserResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json';

	export const searchAsRightsUser = ({searchText, resourceTypeId, rightId, resourceTypeRecurse, isValid, includeSignatureFile, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchAsRightsUser';

		const params = {
			searchText,
			resourceTypeId,
			rightId,
			resourceTypeRecurse,
			isValid,
			includeSignatureFile,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'searchAsRightsUser',
			responseSchema: searchAsRightsUserResponseSchema
		});
	};

	searchAsRightsUser.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffAsRightsUserResponse.json';

	/**
	 * Search as User
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchAsUser()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchAsUser()
	 *
	 * enterpriseId: string
	 * username: string
	 * nationalProviderId: string
	 * givenName: string
	 * familyName: string
	 * returns: StaffAsUserResponse
	 */
	import searchAsUserResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffAsUserResponse.json';

	export const searchAsUser = ({enterpriseId, username, nationalProviderId, givenName, familyName, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchAsUser';

		const params = {
			enterpriseId,
			username,
			nationalProviderId,
			givenName,
			familyName
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'searchAsUser',
			responseSchema: searchAsUserResponseSchema
		});
	};

	searchAsUser.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffAsUserResponse.json';

	/**
	 * Search the Merge Candidate
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchMergeCandidate()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchMergeCandidate()
	 *
	 * staffId: number
	 * staffMasterId: string
	 * staffSimpleRequest: StaffSimpleRequest
	 * returns: StaffQueryResponse
	 */
	import searchMergeCandidateRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';
	import searchMergeCandidateResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse.json';

	export const searchMergeCandidate = (staffSimpleRequest, {staffId, staffMasterId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchMergeCandidate/' + staffId + '/' + staffMasterId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffSimpleRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staffs-svc',
			action: 'searchMergeCandidate',
			requestSchema: searchMergeCandidateRequestSchema,
			responseSchema: searchMergeCandidateResponseSchema
		});
	};

	searchMergeCandidate.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';
	searchMergeCandidate.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffQueryResponse.json';

	/**
	 * Search physicians
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchPhysician()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchPhysician()
	 *
	 * searchText: string
	 * active: boolean
	 * returns: StaffSearchByTypeRequest
	 */
	import searchPhysicianResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	export const searchPhysician = ({searchText, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchPhysician';

		const params = {
			searchText,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'searchPhysician',
			responseSchema: searchPhysicianResponseSchema
		});
	};

	searchPhysician.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	/**
	 * Search providers of specific types or name match. An openended search of ALL resource types without SearchText name match is not allowed. ResourceType will be restricted. Returned list is a distinct set of providers
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchProviders()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchProviders()
	 *
	 * searchText: string
	 * resourceTypeId: string
	 * specialty: string
	 * zipPostalCode: string
	 * active: boolean
	 * isUser: boolean
	 * isUserOnly: boolean
	 * returns: StaffSearchByTypeRequest
	 */
	import searchProvidersResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	export const searchProviders = ({searchText, resourceTypeId, specialty, zipPostalCode, active, isUser, isUserOnly, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchProviders';

		const params = {
			searchText,
			resourceTypeId,
			specialty,
			zipPostalCode,
			active,
			isUser,
			isUserOnly
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'searchProviders',
			responseSchema: searchProvidersResponseSchema
		});
	};

	searchProviders.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchByTypeRequest.json';

	/**
	 * Method since  EnterpriseID=ALL is  being stripped out of XeSearch In order to limit scope of an all search, query is built of limited elements IsMultipleAddress in message tells whether single/multiple address should be returned
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchStaff()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchStaff()
	 *
	 * staffSearchRequest: StaffSearchRequest
	 * returns: SearchXeStaffResponse
	 */
	import searchStaffRequestSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSearchRequest.json';
	import searchStaffResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse.json';

	export const searchStaff = (staffSearchRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchStaff';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = staffSearchRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-staffs-svc',
			action: 'searchStaff',
			requestSchema: searchStaffRequestSchema,
			responseSchema: searchStaffResponseSchema
		});
	};

	searchStaff.requestSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSearchRequest.json';
	searchStaff.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.SearchXeStaffResponse.json';

	/**
	 * Search Staff with FamilyName, GivenName and DirectAddress (y/n)
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSearchStaffSimple()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.searchStaffSimple()
	 *
	 * familyName: string
	 * givenName: string
	 * hasDirectAddress: boolean
	 * returns: BaseXeStaff
	 */
	import searchStaffSimpleResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff.json';

	export const searchStaffSimple = ({familyName, givenName, hasDirectAddress, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/searchStaffSimple';

		const params = {
			familyName,
			givenName,
			hasDirectAddress
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'searchStaffSimple',
			responseSchema: searchStaffSimpleResponseSchema
		});
	};

	searchStaffSimple.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.BaseXeStaff.json';

	/**
	 * Deactivate the Staff
	 * @see com.thrasys.xnet.app.user.handler.StaffHandler.doSetIsValid()
	 * @see com.thrasys.xnet.app.resthandler.staff.StaffService.setIsValid()
	 *
	 * staffId: number
	 * isValid: boolean
	 * returns: StaffSimpleRequest
	 */
	import setIsValidResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';

	export const setIsValid = ({staffId, isValid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/setIsValid/' + staffId;

		const params = {
			isValid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-staffs-svc',
			action: 'setIsValid',
			responseSchema: setIsValidResponseSchema
		});
	};

	setIsValid.responseSchema = 'com.thrasys.xnet.app.xmlobjects.staff.StaffSimpleRequest.json';
