/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Access application label display text from label ids and user locale.
 */

	/**
	 * insert a Label.
	 * @see com.thrasys.xnet.app.common.handler.LabelHandler.doAdd()
	 * @see com.thrasys.xnet.app.resthandler.label.LabelService.add()
	 *
	 * assetXeAppLabels: AssetXeAppLabels
	 * returns: AssetXeAppLabels
	 */
	import addRequestSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';
	import addResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	export const add = (assetXeAppLabels, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/label/add';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeAppLabels;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-labels-svc',
			action: 'add',
			requestSchema: addRequestSchema,
			responseSchema: addResponseSchema
		});
	};

	add.requestSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';
	add.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	/**
	 * update a Label.
	 * @see com.thrasys.xnet.app.common.handler.LabelHandler.doEnter()
	 * @see com.thrasys.xnet.app.resthandler.label.LabelService.enter()
	 *
	 * assetXeAppLabels: AssetXeAppLabels
	 * returns: AssetXeAppLabels
	 */
	import enterRequestSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';
	import enterResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	export const enter = (assetXeAppLabels, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/label/enter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeAppLabels;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-labels-svc',
			action: 'enter',
			requestSchema: enterRequestSchema,
			responseSchema: enterResponseSchema
		});
	};

	enter.requestSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';
	enter.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	/**
	 * retrieve labels using the user locale or locale specified in object attributes
	 * @see com.thrasys.xnet.app.common.handler.LabelHandler.doQueryCache()
	 * @see com.thrasys.xnet.app.resthandler.label.LabelService.queryCache()
	 *
	 * labelId: string[]
	 * labelType: string
	 * returns: AssetXeAppLabels
	 */
	import queryCacheResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	export const queryCache = ({labelId, labelType, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/label';

		const params = {
			labelId,
			labelType
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-labels-svc',
			action: 'queryCache',
			responseSchema: queryCacheResponseSchema
		});
	};

	queryCache.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	/**
	 * retrieve app labels using the user locale or locale specified in object attributes - to be deprecated, use @GET instead
	 * @see com.thrasys.xnet.app.common.handler.LabelHandler.doQueryDbList()
	 * @see com.thrasys.xnet.app.resthandler.label.LabelService.queryDbList()
	 *
	 * labelId: string[]
	 * returns: AssetXeAppLabels
	 */
	import queryDbListResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeAppLabels.json';

	export const queryDbList = ({labelId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/label/queryDbList';

		const params = {
			labelId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-labels-svc',
			action: 'queryDbList',
			responseSchema: queryDbListResponseSchema
		});
	};

	queryDbList.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeAppLabels.json';
