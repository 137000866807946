/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Methods pertaining to clinical documents for patients.
 */

	/**
	 * Return a list of clinical documents that match the search criteria.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.browse()
	 *
	 * ipid: number
	 * ivid: number
	 * associationType: string
	 * resourceId: number
	 * status: string
	 * fromDate: Date
	 * toDate: Date
	 * active: boolean
	 * isInError: boolean
	 * isRecover: boolean
	 * assessmentId: number[]
	 * assessmentTypeId: string[]
	 * wellKnownId: string[]
	 * returns: XeVisitAssessmentList
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList.json';

	export const browse = ({ipid, ivid, associationType, resourceId, status, fromDate, toDate, active, isInError, isRecover, assessmentId, assessmentTypeId, wellKnownId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/list';

		const params = {
			ipid,
			ivid,
			associationType,
			resourceId,
			status,
			fromDate,
			toDate,
			active,
			isInError,
			isRecover,
			assessmentId,
			assessmentTypeId,
			wellKnownId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList.json';

	/**
	 * Return an existing instance if it exists, or new instance of a specific clinical document for a patient. The AssessmentID, WellKnownID or Code and CodeTypeID are required to identify the docuemnt.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doCreateOrFind()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.createOrFind()
	 *
	 * assessmentId: number
	 * wellKnownId: string
	 * code: string
	 * codeTypeId: string
	 * ipid: number
	 * ivid: number
	 * returns: FactXeVisitAssessment
	 */
	import createOrFindResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	export const createOrFind = ({assessmentId, wellKnownId, code, codeTypeId, ipid, ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/createOrFind';

		const params = {
			assessmentId,
			wellKnownId,
			code,
			codeTypeId,
			ipid,
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'createOrFind',
			responseSchema: createOrFindResponseSchema
		});
	};

	createOrFind.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	/**
	 * Return the full details of a specific clinical document for a patient.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.getDetails()
	 *
	 * visitAssessmentId: number
	 * returns: FactXeVisitAssessment
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	export const getDetails = ({visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instanceDetails/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	/**
	 * Return a new instance of a specific clinical document for a patient. The AssessmentID, WellKnownID or Code and CodeTypeID are required to identify the docuemnt.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doGetFromSpec()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.getFromSpec()
	 *
	 * assessmentId: number
	 * wellKnownId: string
	 * code: string
	 * codeTypeId: string
	 * ipid: number
	 * ivid: number
	 * orderId: number
	 * returns: FactXeVisitAssessment
	 */
	import getFromSpecResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	export const getFromSpec = ({assessmentId, wellKnownId, code, codeTypeId, ipid, ivid, orderId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instanceSpec';

		const params = {
			assessmentId,
			wellKnownId,
			code,
			codeTypeId,
			ipid,
			ivid,
			orderId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'getFromSpec',
			responseSchema: getFromSpecResponseSchema
		});
	};

	getFromSpec.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	/**
	 * Return the quick save status for the specified documents.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doGetPollList()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.getPollList()
	 *
	 * visitAssessmentId: number[]
	 * returns: XeVisitAssessmentPollReponse
	 */
	import getPollListResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	export const getPollList = ({visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/stateList';

		const params = {
			visitAssessmentId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'getPollList',
			responseSchema: getPollListResponseSchema
		});
	};

	getPollList.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	/**
	 * Return a specific version of a clinical document for a patient.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doGetVersion()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.getVersion()
	 *
	 * versionId: number
	 * visitAssessmentId: number
	 * version: number
	 * returns: FactXeVisitAssessment
	 */
	import getVersionResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	export const getVersion = ({versionId, visitAssessmentId, version, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instanceVersion';

		const params = {
			versionId,
			visitAssessmentId,
			version
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'getVersion',
			responseSchema: getVersionResponseSchema
		});
	};

	getVersion.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';

	/**
	 * Update an instance of a specific clinical document for a patient to a different IVID. This takes care of SmartBookingInstance etc.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doLink()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.link()
	 *
	 * visitAssessmentId: number
	 * assetXeVisitAssessment: AssetXeVisitAssessment
	 * returns: AssetXeVisitAssessment
	 */
	import linkRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';
	import linkResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	export const link = (assetXeVisitAssessment, {visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/relink/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeVisitAssessment;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'link',
			requestSchema: linkRequestSchema,
			responseSchema: linkResponseSchema
		});
	};

	link.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';
	link.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	/**
	 * Mark this clinical document (and associated data) in error
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.markInError()
	 *
	 * visitAssessmentId: number
	 * factXeVisitAssessment: FactXeVisitAssessment
	 * returns: AssetXeVisitAssessment
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	export const markInError = (factXeVisitAssessment, {visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/markInError/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = factXeVisitAssessment;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FactXeVisitAssessment.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	/**
	 * Mark this clinical document (and associated data) in error
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doMarkVersionInError()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.markVersionInError()
	 *
	 * visitAssessmentId: number
	 * xeVisitAssessment: XeVisitAssessment
	 * returns: AssetXeVisitAssessment
	 */
	import markVersionInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessment.json';
	import markVersionInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	export const markVersionInError = (xeVisitAssessment, {visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/version/markInError/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeVisitAssessment;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'markVersionInError',
			requestSchema: markVersionInErrorRequestSchema,
			responseSchema: markVersionInErrorResponseSchema
		});
	};

	markVersionInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessment.json';
	markVersionInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

	/**
	 * Update the cdoc data object
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doQuickSave()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.quickSave()
	 *
	 * visitAssessmentId: number
	 * fullXeVisitAssessment: FullXeVisitAssessment
	 * returns: XeVisitAssessmentPollReponse
	 */
	import quickSaveRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment.json';
	import quickSaveResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	export const quickSave = (fullXeVisitAssessment, {visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = fullXeVisitAssessment;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'quickSave',
			requestSchema: quickSaveRequestSchema,
			responseSchema: quickSaveResponseSchema
		});
	};

	quickSave.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment.json';
	quickSave.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	/**
	 * Update the cdoc data object
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doQuickSaveInsert()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.quickSaveInsert()
	 *
	 * fullXeVisitAssessment: FullXeVisitAssessment
	 * returns: XeVisitAssessmentPollReponse
	 */
	import quickSaveInsertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment.json';
	import quickSaveInsertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	export const quickSaveInsert = (fullXeVisitAssessment, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = fullXeVisitAssessment;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'quickSaveInsert',
			requestSchema: quickSaveInsertRequestSchema,
			responseSchema: quickSaveInsertResponseSchema
		});
	};

	quickSaveInsert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.FullXeVisitAssessment.json';
	quickSaveInsert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	/**
	 * Push the quick save status from y -> p (processing).
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doRecoverQuick()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.recoverQuick()
	 *
	 * visitAssessmentId: number
	 * xeVisitAssessmentRequest: XeVisitAssessmentRequest
	 * returns: XeVisitAssessmentPollReponse
	 */
	import recoverQuickRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentRequest.json';
	import recoverQuickResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	export const recoverQuick = (xeVisitAssessmentRequest, {visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/recoverState/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeVisitAssessmentRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-visit-clindocs-svc',
			action: 'recoverQuick',
			requestSchema: recoverQuickRequestSchema,
			responseSchema: recoverQuickResponseSchema
		});
	};

	recoverQuick.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentRequest.json';
	recoverQuick.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentPollReponse.json';

	/**
	 * Return a summary of a specific clinical document for a patient.
	 * @see com.thrasys.xnet.erp.clinical.handler.VisitClindocHandler.doSummary()
	 * @see com.thrasys.xnet.erp.resthandler.visitclindoc.VisitClindocService.summary()
	 *
	 * visitAssessmentId: number
	 * returns: XeVisitAssessmentList
	 */
	import summaryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList.json';

	export const summary = ({visitAssessmentId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/cdocs/instance/summary/' + visitAssessmentId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-visit-clindocs-svc',
			action: 'summary',
			responseSchema: summaryResponseSchema
		});
	};

	summary.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitassessment.XeVisitAssessmentList.json';
