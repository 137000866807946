/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/maintain uploaded documents and document sets.
 */

	/**
	 * Add a new document.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doAddDocument()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.addDocument()
	 *
	 * xeScanDoc: XeScanDoc
	 * returns: DocumentEmptyRequest
	 */
	import addDocumentRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
	import addDocumentResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.DocumentEmptyRequest.json';

	export const addDocument = (xeScanDoc, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDoc;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-docs-svc',
			action: 'addDocument',
			requestSchema: addDocumentRequestSchema,
			responseSchema: addDocumentResponseSchema
		});
	};

	addDocument.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
	addDocument.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.DocumentEmptyRequest.json';

	/**
	 * Detete (deactivate) the document with given ID.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doDeleteDocument()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.deleteDocument()
	 *
	 * scanDocId: number
	 * errorDescription: string
	 * returns: XeDocumentResponse
	 */
	import deleteDocumentResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const deleteDocument = ({scanDocId, errorDescription, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/' + scanDocId;

		const params = {
			errorDescription
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-docs-svc',
			action: 'deleteDocument',
			responseSchema: deleteDocumentResponseSchema
		});
	};

	deleteDocument.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Get the document with given ID.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doGetDocument()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.getDocument()
	 *
	 * scanDocId: number
	 * returns: XeScanDoc
	 */
	import getDocumentResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';

	export const getDocument = ({scanDocId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/' + scanDocId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-docs-svc',
			action: 'getDocument',
			responseSchema: getDocumentResponseSchema
		});
	};

	getDocument.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';

	/**
	 * Search for documents.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doGetDocuments()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.getDocuments()
	 *
	 * scanDocId: number
	 * ipid: number
	 * ivid: number
	 * programId: string
	 * categoryId: string
	 * docTypeId: string
	 * fileName: string
	 * from: Date
	 * to: Date
	 * unlinkedOnly: boolean
	 * active: boolean
	 * returns: XeScanDoc
	 */
	import getDocumentsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';

	export const getDocuments = ({scanDocId, ipid, ivid, programId, categoryId, docTypeId, fileName, from, to, unlinkedOnly, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents';

		const params = {
			scanDocId,
			ipid,
			ivid,
			programId,
			categoryId,
			docTypeId,
			fileName,
			from,
			to,
			unlinkedOnly,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-docs-svc',
			action: 'getDocuments',
			responseSchema: getDocumentsResponseSchema
		});
	};

	getDocuments.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';

	/**
	 * Return contents of specified FileID with/without transformation.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doGetFile()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.getFile()
	 *
	 * fileId: number
	 * docTypeId: string
	 * isTransform: boolean
	 * returns: Response
	 */

	export const getFile = ({fileId, docTypeId, isTransform, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/file';

		const params = {
			fileId,
			docTypeId,
			isTransform
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'blob',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-scan-docs-svc',
			action: 'getFile'
		});
	};


	/**
	 * Update the document with given ID.
	 * @see com.thrasys.xnet.erp.clinical.handler.ScanDocHandler.doUpdateDocument()
	 * @see com.thrasys.xnet.erp.resthandler.scandoc.ScanDocService.updateDocument()
	 *
	 * scanDocId: number
	 * xeScanDoc: XeScanDoc
	 * returns: DocumentEmptyRequest
	 */
	import updateDocumentRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
	import updateDocumentResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.DocumentEmptyRequest.json';

	export const updateDocument = (xeScanDoc, {scanDocId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/documents/' + scanDocId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeScanDoc;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-scan-docs-svc',
			action: 'updateDocument',
			requestSchema: updateDocumentRequestSchema,
			responseSchema: updateDocumentResponseSchema
		});
	};

	updateDocument.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.XeScanDoc.json';
	updateDocument.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.DocumentEmptyRequest.json';
