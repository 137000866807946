/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Handle Patient specific messages
 */

	/**
	 * Add a modifier code record to a modifier catalog.  The CodeRecordID is required.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doAddModifierCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.addModifierCatalogServiceCode()
	 *
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import addModifierCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import addModifierCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const addModifierCatalogServiceCode = (serviceCodeCatalogItemRequest, {catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/modifier/item/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'addModifierCatalogServiceCode',
			requestSchema: addModifierCatalogServiceCodeRequestSchema,
			responseSchema: addModifierCatalogServiceCodeResponseSchema
		});
	};

	addModifierCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	addModifierCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Add a service code record to a procedure charge catalog. CodeRecordID is required.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doAddProcedureCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.addProcedureCatalogServiceCode()
	 *
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: XeServiceCodeRefCatDerived
	 */
	import addProcedureCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import addProcedureCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	export const addProcedureCatalogServiceCode = (serviceCodeCatalogItemRequest, {catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/procedure/item/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'addProcedureCatalogServiceCode',
			requestSchema: addProcedureCatalogServiceCodeRequestSchema,
			responseSchema: addProcedureCatalogServiceCodeResponseSchema
		});
	};

	addProcedureCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	addProcedureCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	/**
	 * Add a service code record to a visit charge catalog. CodeRecordID is required.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doAddVisitChargeCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.addVisitChargeCatalogServiceCode()
	 *
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import addVisitChargeCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import addVisitChargeCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const addVisitChargeCatalogServiceCode = (serviceCodeCatalogItemRequest, {catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/visitCharge/item/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'addVisitChargeCatalogServiceCode',
			requestSchema: addVisitChargeCatalogServiceCodeRequestSchema,
			responseSchema: addVisitChargeCatalogServiceCodeResponseSchema
		});
	};

	addVisitChargeCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	addVisitChargeCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Get all service name modifying catalogs.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetCatalogModifiedName()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getCatalogModifiedName()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatalogWithItems
	 */
	import getCatalogModifiedNameResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	export const getCatalogModifiedName = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/catalog/modifiedName';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getCatalogModifiedName',
			responseSchema: getCatalogModifiedNameResponseSchema
		});
	};

	getCatalogModifiedName.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	/**
	 * Get Service Group Codes for which the service groups contain the specified service code.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetGroupCodesContainService()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getGroupCodesContainService()
	 *
	 * code: string
	 * codeTypeId: string
	 * returns: SearchXeServiceCodeCatalog
	 */
	import getGroupCodesContainServiceResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	export const getGroupCodesContainService = ({code, codeTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/serviceGroup/groupCodes/' + code;

		const params = {
			codeTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getGroupCodesContainService',
			responseSchema: getGroupCodesContainServiceResponseSchema
		});
	};

	getGroupCodesContainService.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	/**
	 * Get all service code records from a service name modifying catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetModifiedNameCatalogServiceCodes()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getModifiedNameCatalogServiceCodes()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatDerived
	 */
	import getModifiedNameCatalogServiceCodesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	export const getModifiedNameCatalogServiceCodes = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/catalog/modifiedName/items';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getModifiedNameCatalogServiceCodes',
			responseSchema: getModifiedNameCatalogServiceCodesResponseSchema
		});
	};

	getModifiedNameCatalogServiceCodes.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	/**
	 * Get all service code records from a service name modifying catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetModifiedNameMap()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getModifiedNameMap()
	 *
	 * returns: MapItem
	 */
	import getModifiedNameMapResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.map.MapItem.json';

	export const getModifiedNameMap = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/catalog/modifiedName/map';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getModifiedNameMap',
			responseSchema: getModifiedNameMapResponseSchema
		});
	};

	getModifiedNameMap.responseSchema = 'com.thrasys.xnet.app.xmlobjects.map.MapItem.json';

	/**
	 * Get all modifier code records from a modifier catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetModifierCatalogServiceCodes()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getModifierCatalogServiceCodes()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatDerived
	 */
	import getModifierCatalogServiceCodesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	export const getModifierCatalogServiceCodes = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/modifier/items';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getModifierCatalogServiceCodes',
			responseSchema: getModifierCatalogServiceCodesResponseSchema
		});
	};

	getModifierCatalogServiceCodes.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	/**
	 * Get all service code records from a procedure charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetProcedureCatalogServiceCodes()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getProcedureCatalogServiceCodes()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatDerived
	 */
	import getProcedureCatalogServiceCodesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	export const getProcedureCatalogServiceCodes = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/procedure/items';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getProcedureCatalogServiceCodes',
			responseSchema: getProcedureCatalogServiceCodesResponseSchema
		});
	};

	getProcedureCatalogServiceCodes.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	/**
	 * Get Service Code record by ServiceCodeID value.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getServiceCode()
	 *
	 * codeRecordId: string
	 * returns: SearchXeServiceCodeCatalog
	 */
	import getServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	export const getServiceCode = ({codeRecordId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/' + codeRecordId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getServiceCode',
			responseSchema: getServiceCodeResponseSchema
		});
	};

	getServiceCode.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	/**
	 * Get Service Codes within the service group specified by the group code.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetServiceCodesInGroup()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getServiceCodesInGroup()
	 *
	 * code: string
	 * returns: SearchXeServiceCodeCatalog
	 */
	import getServiceCodesInGroupResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	export const getServiceCodesInGroup = ({code, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/serviceGroup/serviceCodes/' + code;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getServiceCodesInGroup',
			responseSchema: getServiceCodesInGroupResponseSchema
		});
	};

	getServiceCodesInGroup.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	/**
	 * Get all charge modifier catalogs configurated for the user.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetUserCatalogModifier()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getUserCatalogModifier()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatalogWithItems
	 */
	import getUserCatalogModifierResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	export const getUserCatalogModifier = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/modifier';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getUserCatalogModifier',
			responseSchema: getUserCatalogModifierResponseSchema
		});
	};

	getUserCatalogModifier.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	/**
	 * Get all procedure charge catalogs configurated for the user.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetUserCatalogProcedure()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getUserCatalogProcedure()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatalogWithItems
	 */
	import getUserCatalogProcedureResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	export const getUserCatalogProcedure = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/procedure';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getUserCatalogProcedure',
			responseSchema: getUserCatalogProcedureResponseSchema
		});
	};

	getUserCatalogProcedure.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	/**
	 * Get all visit charge catalogs configurated for the user.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetUserCatalogVisitCharge()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getUserCatalogVisitCharge()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatalogWithItems
	 */
	import getUserCatalogVisitChargeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	export const getUserCatalogVisitCharge = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/visitCharge';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getUserCatalogVisitCharge',
			responseSchema: getUserCatalogVisitChargeResponseSchema
		});
	};

	getUserCatalogVisitCharge.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	/**
	 * Get all visit charge catalogs configurated for the user.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetUserCatalogs()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getUserCatalogs()
	 *
	 * catalogId: number
	 * catalogTypeId: string
	 * catContentTypeId: string
	 * returns: XeServiceCodeRefCatalogWithItems
	 */
	import getUserCatalogsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	export const getUserCatalogs = ({catalogId, catalogTypeId, catContentTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/browse';

		const params = {
			catalogId,
			catalogTypeId,
			catContentTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getUserCatalogs',
			responseSchema: getUserCatalogsResponseSchema
		});
	};

	getUserCatalogs.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatalogWithItems.json';

	/**
	 * Get all service code records from a visit charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doGetVisitChargeCatalogServiceCodes()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.getVisitChargeCatalogServiceCodes()
	 *
	 * catalogId: number
	 * returns: XeServiceCodeRefCatDerived
	 */
	import getVisitChargeCatalogServiceCodesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	export const getVisitChargeCatalogServiceCodes = ({catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/visitCharge/items';

		const params = {
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'getVisitChargeCatalogServiceCodes',
			responseSchema: getVisitChargeCatalogServiceCodesResponseSchema
		});
	};

	getVisitChargeCatalogServiceCodes.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecoderefcatalog.XeServiceCodeRefCatDerived.json';

	/**
	 * Remove a modifier code record from a modifier catalog
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doRemoveModifierCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.removeModifierCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import removeModifierCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const removeModifierCatalogServiceCode = ({catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/modifier/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'removeModifierCatalogServiceCode',
			responseSchema: removeModifierCatalogServiceCodeResponseSchema
		});
	};

	removeModifierCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Remove a service code record from a procedure charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doRemoveProcedureCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.removeProcedureCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import removeProcedureCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const removeProcedureCatalogServiceCode = ({catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/procedure/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'removeProcedureCatalogServiceCode',
			responseSchema: removeProcedureCatalogServiceCodeResponseSchema
		});
	};

	removeProcedureCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Remove a service code record from a visit charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doRemoveVisitChargeCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.removeVisitChargeCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import removeVisitChargeCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const removeVisitChargeCatalogServiceCode = ({catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/visitCharge/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'removeVisitChargeCatalogServiceCode',
			responseSchema: removeVisitChargeCatalogServiceCodeResponseSchema
		});
	};

	removeVisitChargeCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Get Service Code records that match the SearchText (and CodetypeID value if provided). The term can be contained in the field of Code, Name, Keywords or CodeAlias, etc. of a record.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doSearchServiceCodes()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.searchServiceCodes()
	 *
	 * searchText: string
	 * codeTypeId: string
	 * serviceTypeId: string
	 * isModifier: boolean
	 * catalogId: number
	 * active: boolean
	 * returns: SearchXeServiceCodeCatalog
	 */
	import searchServiceCodesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	export const searchServiceCodes = ({searchText, codeTypeId, serviceTypeId, isModifier, catalogId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes';

		const params = {
			searchText,
			codeTypeId,
			serviceTypeId,
			isModifier,
			catalogId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-codes-svc',
			action: 'searchServiceCodes',
			responseSchema: searchServiceCodesResponseSchema
		});
	};

	searchServiceCodes.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.SearchXeServiceCodeCatalog.json';

	/**
	 * Update a modifier code record in a modifier catalog
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doUpdateModifierCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.updateModifierCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import updateModifierCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import updateModifierCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const updateModifierCatalogServiceCode = (serviceCodeCatalogItemRequest, {catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/modifier/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'updateModifierCatalogServiceCode',
			requestSchema: updateModifierCatalogServiceCodeRequestSchema,
			responseSchema: updateModifierCatalogServiceCodeResponseSchema
		});
	};

	updateModifierCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	updateModifierCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Update a service code record in a procedure charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doUpdateProcedureCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.updateProcedureCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import updateProcedureCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import updateProcedureCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const updateProcedureCatalogServiceCode = (serviceCodeCatalogItemRequest, {catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/procedure/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'updateProcedureCatalogServiceCode',
			requestSchema: updateProcedureCatalogServiceCodeRequestSchema,
			responseSchema: updateProcedureCatalogServiceCodeResponseSchema
		});
	};

	updateProcedureCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	updateProcedureCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	/**
	 * Update a service code record in a visit charge catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.ServiceCodeHandler.doUpdateVisitChargeCatalogServiceCode()
	 * @see com.thrasys.xnet.erp.resthandler.servicecode.ServiceCodeService.updateVisitChargeCatalogServiceCode()
	 *
	 * catItemId: number
	 * catalogId: number
	 * serviceCodeCatalogItemRequest: ServiceCodeCatalogItemRequest
	 * returns: AssetXeServiceCodeRefCatDerived
	 */
	import updateVisitChargeCatalogServiceCodeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	import updateVisitChargeCatalogServiceCodeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

	export const updateVisitChargeCatalogServiceCode = (serviceCodeCatalogItemRequest, {catItemId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceCodes/userCatalog/visitCharge/item/' + catItemId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceCodeCatalogItemRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-codes-svc',
			action: 'updateVisitChargeCatalogServiceCode',
			requestSchema: updateVisitChargeCatalogServiceCodeRequestSchema,
			responseSchema: updateVisitChargeCatalogServiceCodeResponseSchema
		});
	};

	updateVisitChargeCatalogServiceCode.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicecodecatalog.ServiceCodeCatalogItemRequest.json';
	updateVisitChargeCatalogServiceCode.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';
