/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Handle Patient specific messages
 */

	/**
	 * Get patient visits matching parameters. Patient and Staff will be searched cross account
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.browse()
	 *
	 * ipid: number
	 * visitTypeId: string[]
	 * startDateMin: Date
	 * startDateMax: Date
	 * isClinicalVisitType: boolean
	 * status: string
	 * isActiveStatus: boolean
	 * active: boolean
	 * staffId: number
	 * facilityId: number
	 * isPrimaryProvider: boolean
	 * returns: XePatientVisitWithProvider
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider.json';

	export const browse = ({ipid, visitTypeId, startDateMin, startDateMax, isClinicalVisitType, status, isActiveStatus, active, staffId, facilityId, isPrimaryProvider, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/browse';

		const params = {
			ipid,
			visitTypeId,
			startDateMin,
			startDateMax,
			isClinicalVisitType,
			status,
			isActiveStatus,
			active,
			staffId,
			facilityId,
			isPrimaryProvider
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitWithProvider.json';

	/**
	 * Create a new visit
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doCreateVisit()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.createVisit()
	 *
	 * createPatientVisitRequest: CreatePatientVisitRequest
	 * returns: CreatePatientVisitRequest
	 */
	import createVisitRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.CreatePatientVisitRequest.json';
	import createVisitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.CreatePatientVisitRequest.json';

	export const createVisit = (createPatientVisitRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/new';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = createPatientVisitRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visits-svc',
			action: 'createVisit',
			requestSchema: createVisitRequestSchema,
			responseSchema: createVisitResponseSchema
		});
	};

	createVisit.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.CreatePatientVisitRequest.json';
	createVisit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.CreatePatientVisitRequest.json';

	/**
	 * Get any object linked to the IVID. Relevant for Appointment relink display of effected objects.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doGetLinkedObjects()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.getLinkedObjects()
	 *
	 * ivid: number
	 * active: boolean
	 * returns: PatientVisitLinkObject
	 */
	import getLinkedObjectsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject.json';

	export const getLinkedObjects = ({ivid, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/linkedObjects';

		const params = {
			ivid,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'getLinkedObjects',
			responseSchema: getLinkedObjectsResponseSchema
		});
	};

	getLinkedObjects.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkObject.json';

	/**
	 * Get the visit tree for relinking options (case, case-program(s), auth, issue)  for  this IPID
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doGetLinkedVisitTree()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.getLinkedVisitTree()
	 *
	 * ipid: number
	 * visitTypeId: string[]
	 * active: boolean
	 * returns: PatientVisitLinkTree
	 */
	import getLinkedVisitTreeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree.json';

	export const getLinkedVisitTree = ({ipid, visitTypeId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/linkedVisitTree';

		const params = {
			ipid,
			visitTypeId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'getLinkedVisitTree',
			responseSchema: getLinkedVisitTreeResponseSchema
		});
	};

	getLinkedVisitTree.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitLinkTree.json';

	/**
	 * Get the smartbook instance for a given IVID and BookID
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doGetSmartBookInstance()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.getSmartBookInstance()
	 *
	 * ivid: number
	 * bookId: string
	 * ipid: number
	 * returns: XeSmartBookInstance
	 */
	import getSmartBookInstanceResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

	export const getSmartBookInstance = ({ivid, bookId, ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/smartbook/instance';

		const params = {
			ivid,
			bookId,
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'getSmartBookInstance',
			responseSchema: getSmartBookInstanceResponseSchema
		});
	};

	getSmartBookInstance.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

	/**
	 * Get Visit Summary information.  For an inpatient visit, this includes the previous inpatient visit.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doGetVisitSummary()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.getVisitSummary()
	 *
	 * ivid: number
	 * returns: PatientVisitSummary
	 */
	import getVisitSummaryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary.json';

	export const getVisitSummary = ({ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/teamSummary/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'getVisitSummary',
			responseSchema: getVisitSummaryResponseSchema
		});
	};

	getVisitSummary.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.PatientVisitSummary.json';

	/**
	 * Get patient visits matching parameters.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doList()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.list()
	 *
	 * ipid: number
	 * searchTerm: string
	 * visitTypeId: string[]
	 * isClinicalVisit: boolean
	 * active: boolean
	 * returns: VisitWidgetResponse
	 */
	import listResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitWidgetResponse.json';

	export const list = ({ipid, searchTerm, visitTypeId, isClinicalVisit, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/list';

		const params = {
			ipid,
			searchTerm,
			visitTypeId,
			isClinicalVisit,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-visits-svc',
			action: 'list',
			responseSchema: listResponseSchema
		});
	};

	list.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitWidgetResponse.json';

	/**
	 * Mark a Visit in Error
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.markInError()
	 *
	 * ivid: number
	 * xePatientVisitMarkInErrorRequest: XePatientVisitMarkInErrorRequest
	 * returns: AssetXePatientVisit
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitMarkInErrorRequest.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXePatientVisit.json';

	export const markInError = (xePatientVisitMarkInErrorRequest, {ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/error/' + ivid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xePatientVisitMarkInErrorRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visits-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.XePatientVisitMarkInErrorRequest.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXePatientVisit.json';

	/**
	 * Save a smartbook tree instance into the visit asset
	 * @see com.thrasys.xnet.erp.patient.handler.PatientVisitHandler.doSaveSmartBookInstance()
	 * @see com.thrasys.xnet.erp.resthandler.patientvisit.PatientVisitService.saveSmartBookInstance()
	 *
	 * visitSmartBookRequest: VisitSmartBookRequest
	 * returns: VisitSmartBookRequest
	 */
	import saveSmartBookInstanceRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitSmartBookRequest.json';
	import saveSmartBookInstanceResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitSmartBookRequest.json';

	export const saveSmartBookInstance = (visitSmartBookRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientVisit/smartbook/instance';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitSmartBookRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-visits-svc',
			action: 'saveSmartBookInstance',
			requestSchema: saveSmartBookInstanceRequestSchema,
			responseSchema: saveSmartBookInstanceResponseSchema
		});
	};

	saveSmartBookInstance.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitSmartBookRequest.json';
	saveSmartBookInstance.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientvisit.VisitSmartBookRequest.json';
