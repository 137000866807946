/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage XeServiceInfo request and asset
 */

	/**
	 * Search service info by name
	 * @see com.thrasys.xnet.erp.ordering.handler.ServiceInfoHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.serviceinfo.ServiceInfoService.browse()
	 *
	 * searchTerm: string
	 * infoTypeId: string[]
	 * active: boolean
	 * returns: AssetXeServiceInfo
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeServiceInfo.json';

	export const browse = ({searchTerm, infoTypeId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceInfo/list';

		const params = {
			searchTerm,
			infoTypeId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-infos-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeServiceInfo.json';

	/**
	 * Retrieve a specific information record.
	 * @see com.thrasys.xnet.erp.ordering.handler.ServiceInfoHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.serviceinfo.ServiceInfoService.getDetails()
	 *
	 * infoId: number
	 * returns: AssetXeServiceInfo
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeServiceInfo.json';

	export const getDetails = ({infoId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/serviceInfo/details';

		const params = {
			infoId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-infos-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeServiceInfo.json';
