/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Creation and maintenance of staff.
 */

	/**
	 * Get the address list for the specified StaffID
	 * @see com.thrasys.xnet.erp.provider.handler.ProviderHandler.doGetAddresses()
	 * @see com.thrasys.xnet.erp.resthandler.provider.ProviderService.getAddresses()
	 *
	 * staffId: number
	 * withNetworkStatus: boolean
	 * withInNetworkOnly: boolean
	 * patientPlanId: string
	 * startDateTime: Date
	 * endDateTime: Date
	 * returns: XeStaffAddress
	 */
	import getAddressesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeStaffAddress.json';

	export const getAddresses = ({staffId, withNetworkStatus, withInNetworkOnly, patientPlanId, startDateTime, endDateTime, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/providerNetwork/address/list/' + staffId;

		const params = {
			withNetworkStatus,
			withInNetworkOnly,
			patientPlanId,
			startDateTime,
			endDateTime
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-providers-svc',
			action: 'getAddresses',
			responseSchema: getAddressesResponseSchema
		});
	};

	getAddresses.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeStaffAddress.json';

	/**
	 * Get all active fax or phon numbers for a provider
	 * @see com.thrasys.xnet.erp.provider.handler.ProviderHandler.doGetPhoneContacts()
	 * @see com.thrasys.xnet.erp.resthandler.provider.ProviderService.getPhoneContacts()
	 *
	 * staffId: number
	 * phoneType: string
	 * returns: CallLogContact
	 */
	import getPhoneContactsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json';

	export const getPhoneContacts = ({staffId, phoneType, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/providerNetwork/phoneContacts';

		const params = {
			staffId,
			phoneType
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-providers-svc',
			action: 'getPhoneContacts',
			responseSchema: getPhoneContactsResponseSchema
		});
	};

	getPhoneContacts.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json';

	/**
	 * Get the list of specialties for a specific provider
	 * @see com.thrasys.xnet.erp.provider.handler.ProviderHandler.doGetSpecialties()
	 * @see com.thrasys.xnet.erp.resthandler.provider.ProviderService.getSpecialties()
	 *
	 * staffId: number
	 * returns: XeStaffSpecialty
	 */
	import getSpecialtiesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeStaffSpecialty.json';

	export const getSpecialties = ({staffId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/providerNetwork/specialty/list/' + staffId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-providers-svc',
			action: 'getSpecialties',
			responseSchema: getSpecialtiesResponseSchema
		});
	};

	getSpecialties.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse$XeStaffSpecialty.json';

	/**
	 * Search providers of specific types or name match. An openended search of ALL resource types without SearchText name match is not allowed. ResourceType will be restricted. Returned list is a distinct set of providers
	 * @see com.thrasys.xnet.erp.provider.handler.ProviderHandler.doSearchProviders()
	 * @see com.thrasys.xnet.erp.resthandler.provider.ProviderService.searchProviders()
	 *
	 * searchText: string
	 * resourceTypeId: string
	 * specialty: string
	 * zipPostalCode: string
	 * withNetworkStatus: boolean
	 * withInNetworkOnly: boolean
	 * patientPlanId: string
	 * startDateTime: Date
	 * endDateTime: Date
	 * serviceId: number
	 * svcCtrFilterBits: number
	 * svcCtrDistance: number
	 * ipid: number
	 * active: boolean
	 * returns: ProviderSearchnetParResponse
	 */
	import searchProvidersResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse.json';

	export const searchProviders = ({searchText, resourceTypeId, specialty, zipPostalCode, withNetworkStatus, withInNetworkOnly, patientPlanId, startDateTime, endDateTime, serviceId, svcCtrFilterBits, svcCtrDistance, ipid, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/staff/providerNetwork/searchProviders';

		const params = {
			searchText,
			resourceTypeId,
			specialty,
			zipPostalCode,
			withNetworkStatus,
			withInNetworkOnly,
			patientPlanId,
			startDateTime,
			endDateTime,
			serviceId,
			svcCtrFilterBits,
			svcCtrDistance,
			ipid,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-providers-svc',
			action: 'searchProviders',
			responseSchema: searchProvidersResponseSchema
		});
	};

	searchProviders.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.staff.ProviderSearchnetParResponse.json';
