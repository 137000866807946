/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/Create artifacts for a patient issue and push it throught the Appeals and Grievance process.
 */

	/**
	 * Add a new task to the issue
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doAddTask()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.addTask()
	 *
	 * issueIvid: number
	 * xePatientIssueTask: XePatientIssueTask
	 * returns: XePatientIssueTask
	 */
	import addTaskRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json';
	import addTaskResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json';

	export const addTask = (xePatientIssueTask, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/addTask/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xePatientIssueTask;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'addTask',
			requestSchema: addTaskRequestSchema,
			responseSchema: addTaskResponseSchema
		});
	};

	addTask.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json';
	addTask.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.XePatientIssueTask.json';

	/**
	 * Close the XePatientIssue
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doClose()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.close()
	 *
	 * issueIvid: number
	 * patientIssueSimpleRequest: PatientIssueSimpleRequest
	 * returns: XeDocumentResponse
	 */
	import closeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	import closeResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const close = (patientIssueSimpleRequest, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/close/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueSimpleRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'close',
			requestSchema: closeRequestSchema,
			responseSchema: closeResponseSchema
		});
	};

	close.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	close.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Create a copy of an existing authorization. Besides a simple copy, special options are available with optional flag parameters.}
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doCopy()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.copy()
	 *
	 * issueIvid: number
	 * patientIssueCopyRequest: PatientIssueCopyRequest
	 * returns: PatientIssueDetails
	 */
	import copyRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueCopyRequest.json';
	import copyResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const copy = (patientIssueCopyRequest, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/copy/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueCopyRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'copy',
			requestSchema: copyRequestSchema,
			responseSchema: copyResponseSchema
		});
	};

	copy.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueCopyRequest.json';
	copy.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	/**
	 * Create a new XePatientIssue record based on a given Claim or/and Auth record
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doCreateFromAuthOrClaim()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.createFromAuthOrClaim()
	 *
	 * patientIssueFromAuthOrClaim: PatientIssueFromAuthOrClaim
	 * returns: PatientIssueFullResponse
	 */
	import createFromAuthOrClaimRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFromAuthOrClaim.json';
	import createFromAuthOrClaimResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse.json';

	export const createFromAuthOrClaim = (patientIssueFromAuthOrClaim, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/createFromAuthOrClaim';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueFromAuthOrClaim;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'createFromAuthOrClaim',
			requestSchema: createFromAuthOrClaimRequestSchema,
			responseSchema: createFromAuthOrClaimResponseSchema
		});
	};

	createFromAuthOrClaim.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFromAuthOrClaim.json';
	createFromAuthOrClaim.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueFullResponse.json';

	/**
	 * Query XePatientIssue with the IssueIVID
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doGet()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.get()
	 *
	 * issueIvid: number
	 * returns: PatientIssueDetails
	 */
	import getResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const get = ({issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/details/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-issues-svc',
			action: 'get',
			responseSchema: getResponseSchema
		});
	};

	get.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	/**
	 * Get a validated service to add to an auth
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doGetServiceOrder()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.getServiceOrder()
	 *
	 * issueIvid: number
	 * serviceId: number
	 * code: string
	 * codeTypeId: string
	 * patientPlanId: string
	 * serviceStartDate: Date
	 * serviceEndDate: Date
	 * groupDetails: boolean
	 * pointOfServiceId: number
	 * preferredProvider: number
	 * returns: PatientIssueDetails
	 */
	import getServiceOrderResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const getServiceOrder = ({issueIvid, serviceId, code, codeTypeId, patientPlanId, serviceStartDate, serviceEndDate, groupDetails, pointOfServiceId, preferredProvider, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/serviceOrder';

		const params = {
			issueIvid,
			serviceId,
			code,
			codeTypeId,
			patientPlanId,
			serviceStartDate,
			serviceEndDate,
			groupDetails,
			pointOfServiceId,
			preferredProvider
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-issues-svc',
			action: 'getServiceOrder',
			responseSchema: getServiceOrderResponseSchema
		});
	};

	getServiceOrder.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	/**
	 * Query XePatientIssue with the defined filters: IPID, IssueIVID, IssueTypeID, ReceivedStart/EndDateTime, Owner, TeamID, IssueNum, DueStart/EndDateTime, WorkState
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doList()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.list()
	 *
	 * patientIssueSimpleRequest: PatientIssueSimpleRequest
	 * returns: PatientIssueListResponse
	 */
	import listRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	import listResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse.json';

	export const list = (patientIssueSimpleRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/list';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueSimpleRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'list',
			requestSchema: listRequestSchema,
			responseSchema: listResponseSchema
		});
	};

	list.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	list.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueListResponse.json';

	/**
	 * Mark the XePatientIssue in error if possible
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.markInError()
	 *
	 * issueIvid: number
	 * patientIssueDetails: PatientIssueDetails
	 * returns: XeDocumentResponse
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const markInError = (patientIssueDetails, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/markInError/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	markInError.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * Notify all members of the Assigned Team after Due Time (to be called by a service periodically, e.g., daily)
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doNotifyAssignedDue()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.notifyAssignedDue()
	 *
	 * notificationTypeId: string
	 * returns: PatientIssueSimpleResponse
	 */
	import notifyAssignedDueResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleResponse.json';

	export const notifyAssignedDue = ({notificationTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/notifyAssignedDue';

		const params = {
			notificationTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-issues-svc',
			action: 'notifyAssignedDue',
			responseSchema: notifyAssignedDueResponseSchema
		});
	};

	notifyAssignedDue.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleResponse.json';

	/**
	 * Queries the History for the given IVID
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doQueryHistory()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.queryHistory()
	 *
	 * issueIvid: number
	 * activityAsset: string[]
	 * returns: XeHistoryView
	 */
	import queryHistoryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';

	export const queryHistory = ({issueIvid, activityAsset, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/history/' + issueIvid;

		const params = {
			activityAsset
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-issues-svc',
			action: 'queryHistory',
			responseSchema: queryHistoryResponseSchema
		});
	};

	queryHistory.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.historyview.XeHistoryView.json';

	/**
	 * Reopen the XePatientIssue with a new cycle of configured workflow
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doReopen()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.reopen()
	 *
	 * issueIvid: number
	 * patientIssueSimpleRequest: PatientIssueSimpleRequest
	 * returns: PatientIssueSimpleResponse
	 */
	import reopenRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	import reopenResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleResponse.json';

	export const reopen = (patientIssueSimpleRequest, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/reopen/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueSimpleRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'reopen',
			requestSchema: reopenRequestSchema,
			responseSchema: reopenResponseSchema
		});
	};

	reopen.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleRequest.json';
	reopen.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueSimpleResponse.json';

	/**
	 * Create or update a draft of an authorization request as a XeAuth record
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doSave()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.save()
	 *
	 * patientIssueDetails: PatientIssueDetails
	 * returns: PatientIssueDetails
	 */
	import saveRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	import saveResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const save = (patientIssueDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/save';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueDetails;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'save',
			requestSchema: saveRequestSchema,
			responseSchema: saveResponseSchema
		});
	};

	save.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	save.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	/**
	 * Submit an existing issue draft in DB to start work
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doSubmit()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.submit()
	 *
	 * patientIssueDetails: PatientIssueDetails
	 * returns: PatientIssueDetails
	 */
	import submitRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	import submitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const submit = (patientIssueDetails, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/submit';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'submit',
			requestSchema: submitRequestSchema,
			responseSchema: submitResponseSchema
		});
	};

	submit.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	submit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	/**
	 * Update the XePatientIssue record
	 * @see com.thrasys.xnet.erp.issues.handler.PatientIssueHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.patientissue.PatientIssueService.update()
	 *
	 * issueIvid: number
	 * patientIssueDetails: PatientIssueDetails
	 * returns: PatientIssueDetails
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';

	export const update = (patientIssueDetails, {issueIvid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/utilization/issue/update/' + issueIvid;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientIssueDetails;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-issues-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientissue.PatientIssueDetails.json';
