/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Create/Update/Reschedule a patient appointment on a user calendar.
 */

	/**
	 * get a list of a patient appointments matching parameters
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.browse()
	 *
	 * ipid: number
	 * ivid: number
	 * statusId: string[]
	 * serviceId: number
	 * serviceBookingId: number
	 * schedCapacityLaneId: number
	 * scheduleId: number
	 * isCalendarDisplay: boolean
	 * startTimeMin: Date
	 * startTimeMax: Date
	 * programId: string
	 * providerId: number[]
	 * visitTypeId: string
	 * isEndStatus: boolean
	 * isExcludeVisitType: boolean
	 * isInError: boolean
	 * returns: ServiceBookingBrowseList
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingBrowseList.json';

	export const browse = ({ipid, ivid, statusId, serviceId, serviceBookingId, schedCapacityLaneId, scheduleId, isCalendarDisplay, startTimeMin, startTimeMax, programId, providerId, visitTypeId, isEndStatus, isExcludeVisitType, isInError, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/browse';

		const params = {
			ipid,
			ivid,
			statusId,
			serviceId,
			serviceBookingId,
			schedCapacityLaneId,
			scheduleId,
			isCalendarDisplay,
			startTimeMin,
			startTimeMax,
			programId,
			providerId,
			visitTypeId,
			isEndStatus,
			isExcludeVisitType,
			isInError
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-bookings-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingBrowseList.json';

	/**
	 * cancel a patient appointment and update orders, vists, etc accordingly 
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doCancel()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.cancel()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: AssetXeServiceBooking
	 */
	import cancelRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import cancelResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	export const cancel = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/cancel';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'cancel',
			requestSchema: cancelRequestSchema,
			responseSchema: cancelResponseSchema
		});
	};

	cancel.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	cancel.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	/**
	 * confirm a reserved booking, post appointment create processing will occur
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doConfirm()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.confirm()
	 *
	 * serviceBookingInsertRequest: ServiceBookingInsertRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import confirmRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	import confirmResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const confirm = (serviceBookingInsertRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/confirm';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingInsertRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'confirm',
			requestSchema: confirmRequestSchema,
			responseSchema: confirmResponseSchema
		});
	};

	confirm.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	confirm.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * cancel  series of patient appointments from a SchedCapacityLaneID/ScheduleID
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doDisenrollAsCancel()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.disenrollAsCancel()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import disenrollAsCancelRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import disenrollAsCancelResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const disenrollAsCancel = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/disenrollAsCancel';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'disenrollAsCancel',
			requestSchema: disenrollAsCancelRequestSchema,
			responseSchema: disenrollAsCancelResponseSchema
		});
	};

	disenrollAsCancel.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	disenrollAsCancel.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * set series of patient appointments from a SchedCapacityLaneID/ScheduleID  as in error
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doDisenrollAsMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.disenrollAsMarkInError()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import disenrollAsMarkInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import disenrollAsMarkInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const disenrollAsMarkInError = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/disenrollAsMarkInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'disenrollAsMarkInError',
			requestSchema: disenrollAsMarkInErrorRequestSchema,
			responseSchema: disenrollAsMarkInErrorResponseSchema
		});
	};

	disenrollAsMarkInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	disenrollAsMarkInError.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * create a series of patient appointments from a SchedCapacityLaneID/ScheduleID for the specified IPID
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doEnroll()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.enroll()
	 *
	 * serviceBookingInsertRequest: ServiceBookingInsertRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import enrollRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	import enrollResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const enroll = (serviceBookingInsertRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/enroll';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingInsertRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'enroll',
			requestSchema: enrollRequestSchema,
			responseSchema: enrollResponseSchema
		});
	};

	enroll.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	enroll.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * get detailed information of a patient appointment  using ServiceBookingID
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.getDetails()
	 *
	 * serviceBookingId: number
	 * returns: ServiceBookingFullDetails
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails.json';

	export const getDetails = ({serviceBookingId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/' + serviceBookingId + '/detail';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-service-bookings-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingFullDetails.json';

	/**
	 * insert a patient appointment
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.insert()
	 *
	 * serviceBookingInsertRequest: ServiceBookingInsertRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const insert = (serviceBookingInsertRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingInsertRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * For the servicebooking specified, relink the parentID of the visit to the IVID in the message
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doLink()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.link()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import linkRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import linkResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const link = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/relink';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'link',
			requestSchema: linkRequestSchema,
			responseSchema: linkResponseSchema
		});
	};

	link.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	link.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * mark a patient appointment as an error and update orders, vists, etc accordingly 
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.markInError()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: AssetXeServiceBooking
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	export const markInError = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	/**
	 * mark a participant as accepting the appointment
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doParticipantAccept()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.participantAccept()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: AssetXeServiceBooking
	 */
	import participantAcceptRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import participantAcceptResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	export const participantAccept = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/accept';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'participantAccept',
			requestSchema: participantAcceptRequestSchema,
			responseSchema: participantAcceptResponseSchema
		});
	};

	participantAccept.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	participantAccept.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	/**
	 * reschedule a patient appointment to a different date, provider, service, or time
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doReschedule()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.reschedule()
	 *
	 * serviceBookingInsertRequest: ServiceBookingInsertRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import rescheduleRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	import rescheduleResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const reschedule = (serviceBookingInsertRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/reschedule';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingInsertRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'reschedule',
			requestSchema: rescheduleRequestSchema,
			responseSchema: rescheduleResponseSchema
		});
	};

	reschedule.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	reschedule.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * reserve time on the calendar with a patient appointment of status reserved, no other processing will take place
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doReserve()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.reserve()
	 *
	 * serviceBookingInsertRequest: ServiceBookingInsertRequest
	 * returns: ServiceBookingInsertRequest
	 */
	import reserveRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	import reserveResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	export const reserve = (serviceBookingInsertRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/reserve';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingInsertRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'reserve',
			requestSchema: reserveRequestSchema,
			responseSchema: reserveResponseSchema
		});
	};

	reserve.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';
	reserve.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingInsertRequest.json';

	/**
	 * update patient appointment data except for time 
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.update()
	 *
	 * serviceBookingUpdateRequest: ServiceBookingUpdateRequest
	 * returns: ServiceBookingUpdateRequest
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';

	export const update = (serviceBookingUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingUpdateRequest.json';

	/**
	 * correct patient appointment time for same day, NOT a reschedule. 
	 * @see com.thrasys.xnet.erp.scheduling.handler.ServiceBookingHandler.doUpdateTime()
	 * @see com.thrasys.xnet.erp.resthandler.servicebooking.ServiceBookingService.updateTime()
	 *
	 * serviceBookingTimeRequest: ServiceBookingTimeRequest
	 * returns: AssetXeServiceBooking
	 */
	import updateTimeRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingTimeRequest.json';
	import updateTimeResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';

	export const updateTime = (serviceBookingTimeRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/schedule/patientAppointments/timeCorrection';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = serviceBookingTimeRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-service-bookings-svc',
			action: 'updateTime',
			requestSchema: updateTimeRequestSchema,
			responseSchema: updateTimeResponseSchema
		});
	};

	updateTime.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.servicebooking.ServiceBookingTimeRequest.json';
	updateTime.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeServiceBooking.json';
