/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manages XePatientProvider asset data
 */

	/**
	 * Adding Patient visit provider
	 * @see com.thrasys.xnet.erp.patient.handler.PatientProviderHandler.doAddVisit()
	 * @see com.thrasys.xnet.erp.resthandler.patientprovider.PatientProviderService.addVisit()
	 *
	 * patientProviderAddVisitRequest: PatientProviderAddVisitRequest
	 * returns: PatientProviderAddVisitResponse
	 */
	import addVisitRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitRequest.json';
	import addVisitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitResponse.json';

	export const addVisit = (patientProviderAddVisitRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientProvider/visit';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientProviderAddVisitRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-providers-svc',
			action: 'addVisit',
			requestSchema: addVisitRequestSchema,
			responseSchema: addVisitResponseSchema
		});
	};

	addVisit.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitRequest.json';
	addVisit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddVisitResponse.json';

	/**
	 * Query patient provider records for the specified IPID.  Unique provider type records are masked by the local record if exist while other type of records are all returned.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientProviderHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.patientprovider.PatientProviderService.browse()
	 *
	 * providerId: number
	 * ipid: number
	 * staffId: number
	 * providerTypeId: string[]
	 * active: boolean
	 * isUnique: string
	 * isUser: boolean
	 * returns: PatientProviderQuery
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json';

	export const browse = ({providerId, ipid, staffId, providerTypeId, active, isUnique, isUser, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientProvider/browse';

		const params = {
			providerId,
			ipid,
			staffId,
			providerTypeId,
			active,
			isUnique,
			isUser
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-providers-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json';

	/**
	 * Insert a new patient provider
	 * @see com.thrasys.xnet.erp.patient.handler.PatientProviderHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.patientprovider.PatientProviderService.insert()
	 *
	 * patientProviderAddRequest: PatientProviderAddRequest
	 * returns: PatientProviderResponse
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json';

	export const insert = (patientProviderAddRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientProvider';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientProviderAddRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-providers-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderAddRequest.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json';

	/**
	 * Query patient provider records for the specified IPID.  Unique provider type records are masked by the local record if exist while other type of records are all returned.
	 * @see com.thrasys.xnet.erp.patient.handler.PatientProviderHandler.doQueryFilteredData()
	 * @see com.thrasys.xnet.erp.resthandler.patientprovider.PatientProviderService.queryFilteredData()
	 *
	 * providerId: number
	 * ipid: number
	 * staffId: number
	 * providerTypeId: string[]
	 * active: boolean
	 * isUnique: string
	 * isUser: boolean
	 * returns: PatientProviderQuery
	 */
	import queryFilteredDataResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json';

	export const queryFilteredData = ({providerId, ipid, staffId, providerTypeId, active, isUnique, isUser, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientProvider/filteredList';

		const params = {
			providerId,
			ipid,
			staffId,
			providerTypeId,
			active,
			isUnique,
			isUser
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-patient-providers-svc',
			action: 'queryFilteredData',
			responseSchema: queryFilteredDataResponseSchema
		});
	};

	queryFilteredData.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderQuery.json';

	/**
	 * Update type and/or active status of a patient provider record
	 * @see com.thrasys.xnet.erp.patient.handler.PatientProviderHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.patientprovider.PatientProviderService.update()
	 *
	 * patientProviderUpdateRequest: PatientProviderUpdateRequest
	 * returns: PatientProviderResponse
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderUpdateRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json';

	export const update = (patientProviderUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/patientProvider';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = patientProviderUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-patient-providers-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderUpdateRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.patientprovider.PatientProviderResponse.json';
